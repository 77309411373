import PubNub from 'pubnub';
import config from '../config.json'
const pubnub = new PubNub({
    publishKey: config.REACT_APP_PUBNUB_PUB_KEY,
    subscribeKey: config.REACT_APP_PUBNUB_SUB_KEY,
    uuid: 'chain-warz',
});

export const publishMessage = async (pubnubClient, message, channel) => {
    await pubnubClient.publish({
        channel,
        message,
    })
};

export default pubnub;
