import "./allowlist.scss";
import { FaXTwitter } from "react-icons/fa6";
import discord from "../../assets/images/disFrame.svg";
import next from "../../assets/images/nextarrow.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { isAddress } from "ethers/lib/utils";
import { initializeApp } from "firebase/app";
import { getAuth, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { STORAGE_KEYS, discord_url, firebaseConfig } from "../../config";
import { get, post } from "../../utils/api/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import { useAddress } from "@thirdweb-dev/react";
import Stepper from "./stepper";
import { FaCheck } from "react-icons/fa";
import { login } from "../../redux/slices/userSlice";
const app = initializeApp(firebaseConfig);
const provider = new TwitterAuthProvider();
provider.setCustomParameters({
  'lang': 'en'
});
const auth = getAuth(app);

const AllowlistStepOne = ({ setStep }) => {
  const address = useAddress();
  const dispatch = useDispatch();
  const { isDiscordVerified, isTwitterVerified, twitterUserName, discordUserName } = useSelector((state) => state.user);

  const authenticateTwitter = async () => {
    let loadingToast;
    try {
      loadingToast = toast.loading("Connecting Twitter ...");
      let result = await signInWithPopup(auth, provider);
      const credential = TwitterAuthProvider.credentialFromResult(result);
      const access_token_key = credential.accessToken;
      const access_token_secret = credential.secret;
      console.log(credential, "twitter creds")
      localStorage.setItem(STORAGE_KEYS.twitter, JSON.stringify({
        access_token_key,
        access_token_secret
      }));
      // The signed-in user info.
      const username = result?.user?.reloadUserInfo?.screenName;
      await post('/client/follow_twitter', {
        twitterUserName: username,
        access_token_secret,
        access_token_key
      });
      window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
      await get('/client/profile').then(response => {
        if (response?.status === 200) {
          let token = localStorage.getItem('accessToken');
          dispatch(login({ ...response.data, token }));
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed To Connect !", {
        toastId: "error"
      });
    }
    finally {
      toast.done(loadingToast);
    }
  }

  const formik = useFormik({
    initialValues: {
      address: address
    },
    validationSchema: Yup.object({
      address: Yup.string().test("isValid", "please connect to continue", value => isAddress(value)).required("please connect to continue."),
    }),
    onSubmit: async () => {
      if (!isTwitterVerified || !isDiscordVerified) {
        toast.error("Connect Twitter and Discord !");
        return;
      }
      setStep(2);
    }
  });

  return (
    <div className="wrapperone">
      <div className="container pb-2">
        <div className="heading-element ">
          <h2 className="text-white text-shadow text-center text-stroke">
            Allowlist
          </h2>
          <Stepper total={2} current={1} />
          <div className="text-center"></div>

          <div className="wallet-form text-center mx-auto col-md-6 rounded-4">
            <div className="py-5">
              <h4>link your</h4>

              <div className="d-flex  flex-wrap justify-content-center py-3 gap-3 text-center ">
                {
                  isTwitterVerified && twitterUserName ?
                    <button className="btn btn-default btn-twidis-check">
                      <span href='' >
                        <span className="btn-default-text">
                          <FaCheck color="white" fontSize={35} />
                        </span>
                      </span>
                    </button> :
                    <button className="btn btn-default btn-twidis" onClick={authenticateTwitter}>
                      <span href='' >
                        <span className="btn-default-text">
                          <FaXTwitter color="white" fontSize={30} className="bg-gadient py-1 px-2 rounded-1" /> <span className="text-gradient">twitter</span>
                        </span>
                      </span>
                    </button>
                }
                {
                  isDiscordVerified && discordUserName
                    ?
                    <button className="btn btn-default btn-twidis-check">
                      <span href='' >
                        <span className="btn-default-text">
                          <FaCheck color="white" fontSize={35} />
                        </span>
                      </span>
                    </button> :
                    <button className="btn btn-default btn-twidis" onClick={() => {
                      window.open(discord_url, "_parent")
                    }}>
                      <span >
                        <span className="btn-default-text">
                          <img src={discord} width={35} alt="" /> Discord
                        </span>
                      </span>
                    </button>
                }

              </div>

              <h4 className="mt-2">CONNECTED WALLET ADDRESS</h4>
              <form onSubmit={formik.handleSubmit}>
                <div className="col-md-10 mx-auto mt-5">
                  <input
                    type="text"
                    name="address"
                    disabled
                    className="form-control wallet-email text-light form-control-lg bg-transparent fs-6"
                    placeholder="wallet address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <p className="text-danger">
                      {formik.errors.address}
                    </p>
                  ) : null}

                  <button
                    className="btn btn-default btn-next mt-4"
                  >
                    <span className="btn-default-text">
                      next <img src={next} width={35} alt="" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowlistStepOne;
