/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { getImageSrc } from "../../../utils/common";
import chatIcon from '../../../assets/images/forum.png'

export default function MessageContent({
    message,
    user,
}) {
    const { content, meta } = message;
    return (
        <div key={Date.now() + Math.random()} className="message-item">
            <div className="py-2 d-flex">
                <img className='pic-box' src={getImageSrc(meta?.user?.image)} alt="" />
                <div className="ps-3 w-100">
                    <span >{meta?.user?.username}</span>
                    <p className="text-break text-gray mt-1">{content?.text}</p>
                </div>
            </div>
        </div>
    );
}