/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./battleended.scss";
import { Modal } from "react-bootstrap";
import arrowcyan from "../../assets/images/arrowcyan.svg";
import wcup from "../../assets/images/vcup.png";
import profile from "../../assets/images/profile.png";
import arrow from "../../assets/images/Arrow Go.svg";
import elogo from "../../assets/images/ethlogo.svg";
import ethlogo from "../../assets/images/ethlogo.svg";
import Countdown from "react-countdown";
import twitter from "../../assets/images/twittericon.svg";
import discord from "../../assets/images/discordicon.svg";
import share from "../../assets/images/shareicon.svg";
import Accordion from "react-bootstrap/Accordion";
import ava5 from "../../assets/images/accorimg.png";
import ava4 from "../../assets/images/ava2.svg";
import ava3 from "../../assets/images/ava3.svg";
import DepositPopup from "../modal/modal.component";
import UpcomingBattle from "../upcomingInEndedBattle/upcomingbattle.component";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { get, post } from '../../utils/api/apiHelper';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/commonSlice";
// import { Link } from "react-router-dom";
import { fullDateFormat, getImageSrc, showSlicedText } from '../../utils/common';
import LoadingOverlay from "react-loading-overlay";

let text = `🚀 Ready for an EPIC Battle? 🤩 Entry to the ultimate battle by spreading the word! 🌐 🎮🎁 Tag your gaming squad and dive into the excitement together! 🕹️ Don't miss out on the chance to be part of something extraordinary. 🌟 #GameOn #GamingCommunity #SpreadTheWord 🚀`
LoadingOverlay.propTypes = undefined;
function Spinner() {
  return (
    <div className="custom-loader"></div>
  );
}

const BattleEnded = () => {
  const [isFetching, setFetching] = useState(true);
  const battle_id = window?.location?.pathname?.split('/')?.[2];
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const getPosition = async () => {
    // dispatch(setLoading(true))
    setFetching(true)
    try {
      let response = await get(`/client/getWinnerData?id=${battle_id}`);
      if (response?.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error);
    } finally {
      // dispatch(setLoading(false))
      setFetching(false)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getPosition() }, [])

  if (isFetching) {
    return <Modal centered className="loader" show={true}>
      <LoadingOverlay dingOverlay active={true} spinner={<Spinner />} />
    </Modal>
  }

  return (
    <div className="bg-dark-1 pt-head">
      <div className="container">
        <div className="heading-element ">
          <h2 className="text-white text-shadow text-center text-stroke">
            Battle Ended
          </h2>
        </div>
        <div className="row mx-auto mt-5">
          <div className="col-md-4">
            <div className="bg-gadient  statistics winner-box rounded-5 ">
              <div className="row ">
                <div className="col-7">
                  <h3 className="fs-1 text-white">Winner</h3>
                </div>
                <div className="col-4">
                  <img src={wcup} width={45} alt="" />
                </div>
              </div>

              <div className="d-flex px-4 gap-3  ">
                {
                  data?.isResultDeclared ?
                    <>
                      <figure>
                        <img src={getImageSrc(data?.winner?.user?.profile)} className='pic-boxx' alt="" />
                      </figure>

                      <div className="d-flex flex-column ">
                        <span className="text-white h4 mb-0">{data?.winner?.user?.userName}</span>

                        <span className="p-text h4">
                          {fullDateFormat(data?.winner?.updatedAt)}
                          {/* <img className="arrow" src={arrow} alt="" /> */}
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="d-flex flex-column ">
                        <span className="text-white h4 mb-0">NOT DECLARED YET</span>
                      </div>
                    </>
                }
              </div>

              <h4 className="p-text mt-4">Battle Hash : {showSlicedText(`${data?.contractAddress}`)} <img style={{ cursor: "pointer" }} src={arrowcyan} alt="" onClick={() => {
                window.open(`https://sepolia.etherscan.io/address/${data?.contractAddress}`)
              }} /></h4>
            </div>

            <div className="prizepooldiv social-buttons mt-4">
              <div className=" text-center">
                <h4 className="text-white">Want a free entry?</h4>
                {/* <div className="social-btn"> */}
                {/* <button>
                    <img src={twitter} alt="" />
                  </button>
                  <button>
                    <img src={discord} alt="" />
                  </button>
                  <button>
                    <img src={share} alt=""/>
                  </button> */}
                <div className="social-btn d-flex gap-1 justify-content-center py-1">
                  <a className='btn border-4 p-2 bg-white twitter-share-button'
                    href={`https://twitter.com/intent/tweet?url=https://chainwarz.com${window.location.pathname}&text=${text}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <FaXTwitter fontSize={22} className="text-primary" />
                  </a>
                  {/* <a href='https://www.discord.com/' rel="noreferrer" target="_blank" className='btn border-4 p-2 bg-white'>
                    <img src={discord} alt='' />
                  </a> */}
                  <Link className='btn border-4 p-2 bg-white'
                    onClick={() => {
                      navigator.clipboard.writeText(`https://chainwarz.com/battle/${battle_id}`).then(toast.info('Link copied to clipboard !', {
                        toastId: "copy"
                      }))
                    }}
                  >
                    <img src={share} alt='' />
                  </Link>
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className="mt-4">
              <Accordion defaultActiveKey="0" flush className="bordertop pb-4 mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>Battle participants ({data?.participants?.length})</h5>{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    {data?.participants?.map((item, i) => (

                      <div>
                        <div className="border-battle py-2">
                          {/* <img src={ava5} className="me-3" alt="" /> */}
                          <img src={getImageSrc(item?.profile)} className='pic-boxx' alt="" />
                          <span> {item?.userName}</span>
                        </div>
                        {/* <div className=" border-battle py-2">
                        <img src={ava4} className="me-3" alt="" />
                        <span> totaro</span>
                      </div> */}
                      </div>
                    ))
                    }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          <div className="col-md-8 ">
            <div className="row prizepooldiv  py-4 ">
              <div className="col-12 col-md-5 text-center  ">
                <span className="t-prize ">prize pool</span>

                <div className="d-flex justify-content-center ">
                  <img src={elogo} height={40} alt="" className="me-1" />
                  <h2 className="prize-text h1 mb-0 text-center">{data?.prize_pool}</h2>
                </div>
              </div>

              <div className="col-6 col-md-4 text-center mt-2">
                <span className="t-prize">Your fighters</span>

                <h4 className="h1 text-white">{data?.you_bought}</h4>
              </div>

              <div className="col-6 col-md-3 text-center mt-2 ">
                <span className="t-prize">Total fighters</span>

                <h4 className="t-640 h1">{data?.total_tickets_sold}</h4>
              </div>
            </div>

            <div className="row prizepooldiv upcoming mt-4">
              <h1 className="text-center py-4 text-white">Upcoming Battles</h1>
              <UpcomingBattle></UpcomingBattle>

              {/* <div className="col-md-6  pb-4">
                <div className="border overflow-hidden position-relative rounded-4 text-center">
                  <figure className="img-color3">
                    <img src={b3} alt="" className=" py-2 " />
                  </figure>
                  <div className="battle-content-area">
                    <div className="  mt-2  btn-h1 ">
                      <div className="d-flex justify-content-center   ">
                        <span className="t-price text-info">
                          entry <br />
                          price
                        </span>
                        <div>
                        <img src={ethlogo} width={20} alt=""  className="ms-3"/>
                          <span className="fs-4 text-white"> 0.008</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-white rounded-2 mt-3 btn-pri text-start  btn-gadient">
                      <div className="d-flex justify-content-center ">
                        <span className="t-size">
                          price <br />
                          pool
                        </span>
                        <div>
                        <img src={ethlogo} width={20} alt="" className="ms-3"/>
                          <span className="fs-3 "> 0.031</span>
                        </div>
                      </div>
                    </div>
                  
                    <button className=" btn btn-default btn-def mt-4" onClick={() => setModalShow(true)}>
                      <span className="btn-default-text fs-5 ">enter now</span>
                    </button>
                    
                    
                    
                    <div className="countdown-outer countdown-out ct-out countdown mt-2 bg-gadient mx-auto rounded-4">
                      <div className="countdown-inner  bg-dark-1 rounded-3 position-relative">
                        <Countdown date={Date.now() + 60000000} />
                      </div>
                    </div>
                    <p className="text-primary close mt-3 ">closes today</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 pb-4">
                <div className="border overflow-hidden position-relative rounded-4 text-center ">
                  <figure className="img-color2">
                    <img src={b4} alt="" className=" py-2 " />
                  </figure>
                  <div className="battle-content-area">
                    <div className="  mt-2  btn-h1 ">
                      <div className="d-flex justify-content-center   ">
                        <span className="t-price text-info">
                          entry <br />
                          price
                        </span>
                        <div>
                        <img src={ethlogo} width={20}  className="ms-3" alt=""/>
                          <span className="fs-4 text-white"> 0.008</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-white rounded-2 mt-3 btn-pri text-start  btn-gadient">
                      <div className="d-flex justify-content-center  ">
                        <span className="t-size">
                          price <br />
                          pool
                        </span>
                        <div>
                        <img src={ethlogo} width={20}  className="ms-3" alt=""/>
                          <span className="fs-3 "> 0.031</span>
                        </div>
                      </div>
                    </div>

                    <button className=" btn btn-default btn-def mt-4" onClick={() => setModalShow(true)}>
                      <span className="btn-default-text fs-5 ">enter now</span>
                    </button>

                    <div className="countdown-outer countdown-out mt-2 countdown bg-gadient mx-auto rounded-4">
                      <div className=" countdown-inner bg-dark-1 rounded-3 position-relative">
                        <Countdown date={Date.now() + 60000000} />
                      </div>
                    </div>
                    <p className="text-primary close mt-3">closes today</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 pb-4">
                <div className="border overflow-hidden position-relative rounded-4  text-center">
                  <figure className="img-color1">
                    <img src={b1} alt="" className=" py-2  " />
                  </figure>
                  <div className="battle-content-area">
                    <div className="  mt-2  btn-h1 ">
                      <div className="d-flex justify-content-center   ">
                        <span className="t-price text-info">
                          entry <br />
                          price
                        </span>
                        <div>
                          <img src={ethlogo} width={20} alt="" className="ms-3" />
                          <span className="fs-4 text-white"> 0.008</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-white rounded-2 mt-3 btn-pri text-start  btn-gadient">
                      <div className="d-flex justify-content-center ">
                        <span className="t-size">
                          price <br />
                          pool
                        </span>
                        <div>
                        <img src={ethlogo} width={20} alt="" className="ms-3"/>
                          <span className="fs-3 "> 0.031</span>
                        </div>
                      </div>
                    </div>
                    <button className=" btn btn-default btn-def mt-4" onClick={() => setModalShow(true)}>
                      <span className="btn-default-text fs-5 ">enter now</span>
                    </button>

                    <div className="countdown-outer countdown-out mt-2 countdown bg-gadient mx-auto rounded-4">
                      <div className=" countdown-inner  bg-dark-1 rounded-3 position-relative">
                        <Countdown date={Date.now() + 60000000} />
                      </div>
                    </div>
                    <p className="text-primary close mt-3">closes today</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 pb-4 ">
                <div className="border overflow-hidden position-relative  rounded-4 text-center">
                  <figure className="img-color4">
                    <img src={b2} alt="" className=" py-2  " />
                  </figure>
                  <div className="battle-content-area">
                    <div className="  mt-2  btn-h1 ">
                      <div className="d-flex justify-content-center">
                        <span className="t-price text-info">
                          entry <br />
                          price
                        </span>
                        <div>
                        <img src={ethlogo} width={20} alt="" className="ms-3"/>
                          <span className="fs-4 text-white"> 0.008</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-white rounded-2 mt-3 btn-pri text-start  btn-gadient">
                      <div className="d-flex justify-content-center  ">
                        <span className="t-size">
                          price <br />
                          pool
                        </span>
                        <div>
                        <img src={ethlogo} width={20} alt="" className="ms-3"/>
                          <span className="fs-3 "> 0.031</span>
                        </div>
                      </div>
                    </div>
                    <button className=" btn btn-default btn-def mt-4" onClick={() => setModalShow(true)}>
                      <span className="btn-default-text fs-5 ">enter now</span>
                    </button>

                    <div className="countdown-outer countdown-out countdown  mt-2 bg-gadient mx-auto rounded-4">
                      <div className="countdown-inner  bg-dark-1 rounded-3 position-relative">
                        <Countdown date={Date.now() + 60000000} />
                      </div>
                    </div>
                    <p className="text-primary close mt-3">closes today</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default BattleEnded;
