
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/home.component';
import Policy from "../src/components/policy/policy.components.jsx"
import Allowlist from '../src/components/allowlist'
import Leaderboard from '../src/components/Leaderboard/leaderboard.component'
import Thankyou from '../src/components/allowlist/thankyou.component.jsx'
import Battles from './components/battles/battels.component';
import Winner from './components/winner/winner.component.jsx';
import BattleEnded from './components/battleended/battleended.component';
import BattleLaunch from './components/battlelaunch/battlelaunch.component';
import Editprofile from './auth/editprofile/editprofile.component';
import MyProfile from './components/profile/index.jsx';
import Pagenotfound from './components/pagenotfound/pagenotfound.component';
import React from 'react';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ConfirmPopup from './components/ConfirmPopUp';
import HomeLayout from './layout/HomeLayout.jsx';
import AuthLayout from './layout/AuthLayout.jsx';
import Layout from './layout/Layout.jsx';
import RefferalPopup from './components/modal/reffermodal.jsx';
import Notifications from './components/notifications/notifications.comonent.jsx';
import WalletModal from './layout/header/WalletModal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { showConnectModal } from './redux/slices/commonSlice.js';

function App() {
  const dispatch = useDispatch();
  const { showConnectModal: show } = useSelector((state) => state.common);
  const setShow = (v) => {
    dispatch(showConnectModal(v));
  }

  return (
    <div className="App  bg-dark-1 mh-100">
      <Loader />
      <ToastContainer />
      <BrowserRouter>
        <RefferalPopup />
        <WalletModal setShow={setShow} show={show} />
        <ConfirmPopup />
        <Routes>
          <Route path='/' element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path="policy" element={<Policy />} />
          </Route>
          <Route path='/' element={<Layout />}>
            <Route path="winners" element={<Winner />} />
            <Route path="battles" element={<Battles />} />
            <Route path="leaderboard" element={<Leaderboard />} />
          </Route>
          <Route path='/' element={<AuthLayout />}>
            <Route path="profile" element={<MyProfile />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="allowlist" element={<Allowlist />} />
            <Route path="battle/:id" element={<BattleLaunch />} />
            <Route path="editprofile" element={<Editprofile />} />
            <Route path="thankyou" element={<Thankyou />}></Route>
            <Route path="battleEnded/:id" element={<BattleEnded />}></Route>
          </Route>
          <Route path="*" element={<Pagenotfound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
