import { useState } from "react";
import backarrow from "../../assets/images/Backarrow.svg";
import "./allowliststeptwo.scss";


import ReCAPTCHA from "react-google-recaptcha";
import { post } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/commonSlice";
import { toast } from "react-toastify";
import { useAddress } from "@thirdweb-dev/react";
import Stepper from "./stepper";


const AllowlistStepTwo = ({ setStep, data, setData }) => {
  const address = useAddress();
  const [cp, setCp] = useState(false);
  const dispatch = useDispatch();
  function onChange(value) {
    if (value) {
      setCp(true);
    }
    console.log("Captcha value:", value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

 // Check if any answer is less than 10 characters
 const hasInvalidAnswer = data.some(({ answer }) => answer.length < 10);

 if (hasInvalidAnswer) {
   // Show error message 
   toast.error('Minimum 10 characters required for all answers', {
     toastId: "error"
   });

   return; // Stop the form submission
 }

    if (!cp) {
      let elem = document.getElementById("cp")
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      try {
        dispatch(setLoading(true));
        const response = await post('/client/allowlist', { walletAddress: address, answers: data });
        dispatch(setLoading(false));
        if (response.status === 200) {
          toast.success(response?.message);
          setStep(3);
        } else {
          toast.error(response?.error ?? "Something went wrong.", {
            toastId: "error"
          });
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Failed to save answers!', {
          toastId: "error"
        });
      }
    }
  }
  return (
    <div>
      <div className="pt-head bg-dark-1 ">
        <div className="container ">
          <p className="text-info cursor-pointer" onClick={() => setStep(1)}>
            <span>
              <img src={backarrow} alt="" />
            </span>
            back
          </p>
          <div className="heading-element pb-4 ">
            <h2 className="text-white text-shadow text-white text-center text-stroke">
              Allowlist
            </h2>
            <Stepper total={2} current={2} />
            <div className=" py-5 col-md-8 mx-auto">
              <h3 className="fs-1 text-center text-white">TELL US ABOUT YOURSELF</h3>

              <form className="mt-5 " onSubmit={handleSubmit}>
                {
                  data?.map(({ question, answer }, i) => {
                    return (
                      <div className="mb-4" key={i}>
                        <label htmlFor="Textarea1">
                          <h5 className="formheading  text-white">
                            {i + 1}. {question}
                          </h5>
                        </label>

                        <div className="textarea-load bg-gadient rounded-3">
                          <div className="textarea-inner position-relative">
                            <textarea
                              required
                              className="form-control border-0 bg-dark-1 dark-textarea  p-3 text-white"
                              id="Textarea1"
                              rows="3"
                              value={answer}
                              onChange={(e) => {
                                setData(old => old.map((d, di) => {
                                  return di === i ? {
                                    ...d, answer: e.target.value
                                  } : d
                                }))
                              }}
                              minLength={10}
                              maxLength={400}
                            ></textarea>
                          </div>
                        </div>
                        <p className="text-white text-end char-size pt-2">{answer?.length < 10 ? "minimum characters : 10" : `characters remaining : ${400 - answer?.length}`}</p>
                      </div>
                    )
                  })
                }

                <div className="text-center" id="cp">
                  <div className="mt-5 mx-auto">
                    <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                      onChange={onChange} className="mx-auto recaptcha-element" />
                  </div>

                  <button className="btn btn-default w-50 mt-5">
                    <span className="btn-default-text">Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowlistStepTwo;