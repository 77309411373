import axios from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showConnectModal } from "../../redux/slices/commonSlice";

const api = axios.create({
  baseURL: "https://api.chainwarz.com/api",
  // baseURL: "http://localhost:4401/api",
});

export const useInterceptors = () => {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const setInterceptors = useCallback(() => {
    api.interceptors.request.use((request) => {
      const token = localStorage.getItem('accessToken')
      request.headers.Authorization = token;
      return request
    })
    api.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        console.log(error, 'res error');
        if (error?.response?.data?.status === 401) {
          localStorage.clear()
          dispatch(showConnectModal(true))
          redirect('/battles')
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return setInterceptors
}
export default api