import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice';
import commonReducer from './slices/commonSlice';
const reducer = combineReducers({
  user: userReducer,
  common: commonReducer
});
export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});