import "./notifications.scss";
import React, { useEffect, useState } from 'react';
import { post, get } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/commonSlice";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Notifications = ({ setStep }) => {
  const [data, setData] = useState({});
  const [notificationSettings, setNotificationSettings] = useState([
    { status: false, name: 'Arena' },
    { status: false, name: 'Boss Fight' },
  ]);
  const dispatch = useDispatch();
  const redirect = useNavigate();

  if (notificationSettings[0]?.status === true || notificationSettings[1]?.status === true) {
    const userInfo = JSON.parse(localStorage.getItem('user-info'));
    if (userInfo.email === null) {
      toast.error("Please update your Email");
      redirect('/editprofile');
    }
  }
  
  
  const getNotification = async () => {
    dispatch(setLoading(true))
    try {
      let response = await get('/client/getNotificatonKey');
      if (response?.status === 200) {
        setData(response.data);
        setNotificationSettings(response.data.notificationKey);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false))
    }
  }

  const handleCheckboxChange = (index) => {
    setNotificationSettings((prevSettings) => {
      const updatedSettings = [...prevSettings];
      updatedSettings[index].status = !updatedSettings[index].status;
      return updatedSettings;
    });
  };

  const saveNotifications = async () => {
    try {
      const notificationKey = notificationSettings.map(({ status, name }) => ({
        status: status,
        name: name
      }));
      let response = await post('/client/saveNotificaton', { notificationKey });
      if (response.status === 200) {
        toast.success(response?.message);
        getNotification();
      } else {
        toast.error(response?.error ?? "Something went wrong.", {
          toastId: "error"
        });
        console.error('Failed to save notification settings.');
      }
    } catch (error) {
      console.error('An error occurred while saving notification settings:', error);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div className="wrapperone">
      <div className="container pb-2">
        <div className="heading-element ">
          <h2 className="text-white text-shadow text-center text-stroke">
            Settings
          </h2>
          <div className="text-center"></div>
          <div className="wallet-form text-center mx-auto col-md-6 rounded-4">
            <div className="py-5">
              <h4 className="mb-4">Notifications</h4>
              <div className="row ">
                {notificationSettings.map((setting, index) => (
                  <div className="d-flex col-8 mx-auto" key={index}>
                    <div className="button-switch" id={`button-${index}`}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={setting.status}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <div className="knobs twitter Bell">
                        {/* <i className="fa fa-bell" aria-hidden="true"></i> */}
                      </div>
                      <div className="layer"></div>
                    </div>
                    <button className="btn btn-default btn-twidis">
                      <span href=''>
                        <span className="btn-default-text">
                          <span className="text-gradient">{setting.name}</span>
                        </span>
                      </span>
                    </button>
                  </div>
                ))}
              </div>
              <div className="col-md-10 mx-auto mt-5">
                <button
                  className="btn btn-default btn-next mt-4" onClick={saveNotifications}
                >
                  <span className="btn-default-text">
                    Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
