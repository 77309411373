import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { scrollIntoView } from "../utils/common";
import Header from "./header/header.components";
import Footer from "./footer/footer.component";

export default function Layout() {
    const { hash, pathname } = useLocation();
    useEffect(() => {
        scrollIntoView(hash);
    }, [pathname, hash]);

    return (
        <Fragment>
            <Header />
            <Outlet />
            <Footer />
        </Fragment>
    );
}