/* eslint-disable no-unused-vars */
import React from "react";
import "./profileheader.scss";
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from "../../../assets/images/badge.png";
import arrowcyan from "../../../assets/images/arrowcyan.svg";
import Share from "../../../assets/images/Share-1.svg";
import Instagram from "../../../assets/images/instagram.png";
import Facebook from "../../../assets/images/Facebook.png";
import twiter from "../../../assets/images/twiter.png";
import edit from "../../../assets/images/edit.png";
import back from "../../../assets/images/back.png";
import star1 from '../../../assets/images/Star-s1.png';
import star2 from '../../../assets/images/Star-s2.png';
import ethereum from '../../../assets/images/ethereum-eth-logo.svg';
import { BsPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { logOut } from "../../../redux/slices/userSlice";
import { setWalletConnection } from "../../../redux/slices/commonSlice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { getImageSrc } from "../../../utils/common";
import { ConnectWallet, useDisconnect } from "@thirdweb-dev/react";
import { privateKeyLink } from "../../../utils/thirdweb";
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoDiscord } from 'react-icons/bi'
const ProfileHeader = ({ balance, setModalShow }) => {
    const user = useSelector((state) => state.user);
    const disconnect = useDisconnect();
    const dispatch = useDispatch();
    const redirect = useNavigate();
    const logOutHandler = () => {
        const options = {
            title: 'Are you sure you want to logout?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await disconnect();
                        dispatch(logOut());
                        dispatch(setWalletConnection(false));
                        localStorage.setItem('accessToken', "");
                        localStorage.setItem('walletAddress', "");
                        redirect('/battles');
                        toast.success("Logout successfully!");
                    }
                },
                {
                    label: 'No'
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "custom-alert-overlay"
        };
        confirmAlert(options);
    };

    return (
        <>
            <div className="profile-header text-center text-md-start d-md-flex">
                <div className="profile-header-image me-3">
                    <figure className="bg-gadient bg-animation mx-auto p-1"><img src={getImageSrc(user?.image)} alt="" className="rounded-2 w-100 h-100 object-fit-cover" /></figure>
                </div>
                <div className="profile-header-content">
                    <div className="d-md-flex">
                        <div className="profile-header-content-name">
                            <h2 className="text-white">{user.username} <img src={Badge} alt="" /></h2>
                            <p className="text-gray"> Joined {user.joinedDate?.split("T")[0]}</p>
                        </div>
                        <div className="social-list ms-auto text-center">
                            <ul>
                                {/* <li>
                                    <Dropdown>
                                        <Dropdown.Toggle className="bg-white p-3 social-list-dropdown" id="dropdown-basic">
                                            <Dropdown.Item href=""><img src={Share} alt="" /></Dropdown.Item>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="social-list-inner">
                                            <Dropdown.Item href="https://www.facebook.com/" target="_blank">{<img src={Facebook} alt="" />}</Dropdown.Item>
                                            <Dropdown.Item href="https://www.twitter.com/" target="_blank">{<img src={twiter} alt="" />}</Dropdown.Item>
                                            <Dropdown.Item href="https://www.instagram.com/" target="_blank">{<img src={Instagram} alt="" />}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li> */}
                                <li><Link to={'/editprofile'} className="social-list-btn bg-white mx-3"><img src={edit} alt="" /></Link></li>
                                <li><span onClick={logOutHandler} className="social-list-btn"><img src={back} alt="" /></span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="profile-header-content-inner">
                        <p className="text-break">{user.walletAddress} <img style={{cursor:"pointer"}} src={arrowcyan} alt="" onClick={() => {
                            window.open(`https://sepolia.etherscan.io/address/${user.walletAddress}`)
                        }} /></p>

                        <div>
                            <div className="toggle-button-cover d-flex align-items-center">
                                <div className="d-md-flex align-items-center  me-5">

                                    <div className="button-switch" id="button-1">
                                        <input type="checkbox" className="checkbox" disabled defaultChecked={user?.isTwitterVerified && user?.twitterUserName} />
                                        <div className="knobs twitter"></div>
                                        <div className="layer"></div>
                                    </div>

                                    <span className="text-white ms-md-3">Link twitter</span>
                                </div>
                                <div className="d-md-flex align-items-center">

                                    <div className="button-switch " id="button-1">
                                        <input type="checkbox" className="checkbox" disabled defaultChecked={user?.isDiscordVerified && user?.discordUserName} />
                                        <div className="knobs discord"></div>
                                        <div className="layer "></div>
                                    </div>

                                    <span className="text-white ms-md-3">Link Discord</span>
                                </div>
                            </div>
                            {/* <a rel="noreferrer" href='https://twitter.com/intent/follow?screen_name=ChainwarzGaming' target='_blank'>
                                <FaXTwitter fontSize={32} className='twitter' />
                            </a>
                            <a rel="noreferrer" href='https://discord.gg/3syprfcb8r' target='_blank'>
                                <BiLogoDiscord fontSize={32} className='discord' />
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-2 col-6">
                    <h4 className="text-white">level</h4>
                    <div className="d-md-flex align-items-center   ">
                        <img src={star1} alt="" width={35} />
                        <span className=" h2 text-warning m-0">{user.tier}</span>
                    </div>
                </div>
                <div className="col-md-2 col-6 text-end text-md-start">
                    <h4 className="text-white">points</h4>
                    <div className="d-md-flex align-items-center   ">
                        <img src={star2} alt="" width={35} />
                        <span className=" h2 text-cyan m-0">{user.points}</span>
                    </div>
                </div>
                <div className="col-md-4 col-12 text-start mt-4 mt-md-0">
                    <h4 className="text-white">referral code</h4>
                    <span className="text-break text-cyan h5 p-2">{user.referral_code}</span>
                </div>
                <div className="col-md-4 col-12 text-md-end text-start  mt-4 mt-md-0">
                    <h4 className="text-white">
                        Balance
                    </h4>
                    <div className="">
                        {/* <span className=" h6 text-cyan p-2 text-break">{balance} eth</span> */}
                        <ConnectWallet hideTestnetFaucet hideSwitchToPersonalWallet dropdownPosition={{
                            side: "bottom",
                            align: "center"
                        }} theme={"light"} />
                        {/* <div className="mt-1">
                            {privateKeyLink()}
                        </div> */}
                        {/* <img alt="" src={ethereum} width={25} />
                        <span className=" h2 text-cyan p-2">{balance?.toFixed(8)} eth</span>
                        <span className="iconplus " onClick={() => setModalShow(true)}>
                            <BsPlus className="text-white h3   " />
                        </span> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileHeader;