import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { scrollIntoView } from '../utils/common';
import HomeHeader from './homeheader';
import Footer from './footer/footer.component';

export default function HomeLayout() {
    const { pathname, hash } = useLocation();
    useEffect(() => {
        scrollIntoView(hash);
    }, [pathname, hash])
    return (
        <Fragment>
            <HomeHeader />
            <Outlet />
            <Footer />
        </Fragment>
    );
}
