import React, { useEffect, useState } from "react";
import "./thankyou.scss";
import ava1 from "../../assets/images/Ava.svg";
import copy from "../../assets/images/content_copy.svg";
import { get } from "../../utils/api/apiHelper";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/commonSlice";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
function Thankyou() {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const getPosition = async () => {
    dispatch(setLoading(true))
    try {
      let response = await get('/client/me_position');
      if (response?.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false))
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getPosition() }, [])
  return (
    <div className="wrap">
      <div className="container">
        <div className="row text-center text-white">
          <p className="thank text-white text-shadow text-center text-stroke">Thank you</p>
          <h2 className="text-gradient"> We’re bullish on you!</h2>

          <p className="parag1 py-2">
            Thank you for taking the time to complete our questionnaire! Your
            input is invaluable to us, and it helps us build a strong and
            engaged community.
          </p>

          <p className="parag1 py-2">

            Our team is currently reviewing your responses, and we'll notify you
            if you've been granted the Interview List role in our Discord
            community.
          </p>
        </div>

        <div className="row text-center  inner-content rounded-3 p-3 py-5 ">
          <h1 className="text-white py-3 ">
            You are at <span className="text-gradient">#{data?.position} </span> on the
            <span className="list"> list</span>
          </h1>

          <div className="row mx-0">
            <div className="row mx-0 py-4">
              <div className=" col-md-1 col-6 text-start">
                <p className="text-gray mb-0">Position</p>
              </div>
              <div className="  col-md-3 text-md-center text-end col-6">
                <p className="text-gray mb-0">name</p>
              </div>
              {/* <div className=" col-md-2    text-center d-none d-md-block">
                <p className="text-gray mb-0">invites</p>
              </div> */}
              {/* <div className=" col-md-2 text-center d-none d-md-block">
                <p className="text-gray mb-0">streak</p>
              </div> */}
              <div className=" col-md-4 text-center d-none d-md-block">
                <p className="text-gray mb-0">referral code</p>
              </div>
              <div className=" col-md-2  text-center d-none d-md-block">
                <p className="text-gray mb-0">24H points</p>
              </div>
              <div className="  col-md-2  text-end d-none d-md-block">
                <p className="text-gray mb-0">total points</p>
              </div>
            </div>

            <div className="bg-gadient bg-animation battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix">

              <div className="  bg-blue rounded-4 overflow-hidden">
                <div className="bgcolor">


                  <div className="row mx-0  py-3 ">

                    <div className=" col-6 col-md-1  text-start">
                      <div className="  ">
                        <p className="text-white  text-center">{data?.position}</p>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 text-end text-md-center">
                      <p className="text-white">
                        {data?.userName}
                      </p>
                    </div>
                    {/* <div className=" col-6  text-start text-md-center d-block d-md-none">
                      <p className="text-gray mb-0">invites</p>
                    </div> */}
                    {/* <div className="col-md-2 col-6 text-end text-md-center">
                      <p className="text-cyan d-inline-block text-truncate text-elips">
                        <span className="one"> hello</span>
                      </p>
                    </div> */}
                    {/* <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">streak</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-gray "><span className="one1">100%</span></p>
                  </div> */}
                    <div className=" col-md-3 col-6 text-start text-md-center d-block d-md-none">
                      <p className="text-gray mb-0">referral code</p>
                    </div>
                    <div className="col-md-4 col-6 text-end text-md-center ">
                      <p className=" mb-0  text-cyan d-inline-block text-truncate text-elips ">{data?.referral_code}</p>
                    </div>
                    <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                      <p className="text-gray mb-0">24H points</p>
                    </div>
                    <div className=" col-md-2 col-6 text-end text-md-center ">
                      <p className="text-white mb-0">{data?.points_24hrs}</p>
                    </div>
                    <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                      <p className="text-gray mb-0">total points</p>
                    </div>
                    <div className="col-md-2 col-6 text-end ">
                      <p className="text-white mb-0">{data?.points}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className=" battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix">

              <div className="border rounded-4">
                <div className="row mx-0  py-3 ">

                  <div className=" col-6 col-md-1  text-start">
                    <div>
                      <p className="text-gray ">23</p>
                    </div>

                  </div>
                  <div className="col-6 col-md-2 text-end text-md-center">
                    <p className="text-white">
                      Bob Jonathan
                    </p>
                  </div>
                  <div className=" col-6  text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">invites</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-cyan d-inline-block text-truncate text-elips">
                      <span className="one"> hello</span>
                    </p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">streak</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-gray "><span className="one1">100%</span></p>
                  </div>
                  <div className=" col-md-3 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">referral code</p>
                  </div>
                  <div className="col-md-3 col-6 text-end text-md-center ">
                    <p className=" mb-0  text-cyan d-inline-block text-truncate text-elips ">x23d2cdgct64</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">24H points</p>
                  </div>
                  <div className=" col-md-2 col-6 text-end text-md-center ">
                    <p className="text-gray mb-0">11</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">total points</p>
                  </div>
                  <div className="col-md-2 col-6 text-end ">
                    <p className="text-gray mb-0">324</p>
                  </div>
                </div>
              </div>
            </div>


            <div className=" battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix">

              <div className="border rounded-4">
                <div className="row mx-0  py-3 ">

                  <div className=" col-6 col-md-1  text-start">
                    <p className="text-gray ">23</p>
                  </div>
                  <div className="col-6 col-md-2 text-end text-md-center">
                    <p className="text-white">
                      Bob Jonathan
                    </p>
                  </div>
                  <div className=" col-6  text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">invites</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-cyan d-inline-block text-truncate text-elips">
                      <span className="one"> hello</span>
                    </p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">streak</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-gray "><span className="one1">100%</span></p>
                  </div>
                  <div className=" col-md-3 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">referral code</p>
                  </div>
                  <div className="col-md-3 col-6 text-end text-md-center ">
                    <p className=" mb-0  text-cyan d-inline-block text-truncate text-elips ">x23d2cdgct64</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">24H points</p>
                  </div>
                  <div className=" col-md-2 col-6 text-end text-md-center ">
                    <p className="text-gray mb-0">11</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">total points</p>
                  </div>
                  <div className="col-md-2 col-6 text-end ">
                    <p className="text-gray mb-0">324</p>
                  </div>
                </div>
              </div>
            </div>


            <div className=" battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix">

              <div className="border rounded-4">
                <div className="row mx-0  py-3 ">

                  <div className=" col-6 col-md-1  text-start">
                    <p className="text-gray ">23</p>
                  </div>
                  <div className="col-6 col-md-2 text-end text-md-center">
                    <p className="text-white">
                      Bob Jonathan
                    </p>
                  </div>
                  <div className=" col-6  text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">invites</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-cyan d-inline-block text-truncate text-elips">
                      <span className="one"> hello</span>
                    </p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">streak</p>
                  </div>
                  <div className="col-md-2 col-6 text-end text-md-center">
                    <p className="text-gray "><span className="one1">100%</span></p>
                  </div>
                  <div className=" col-md-3 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">referral code</p>
                  </div>
                  <div className="col-md-3 col-6 text-end text-md-center ">
                    <p className=" mb-0  text-cyan d-inline-block text-truncate text-elips ">x23d2cdgct64</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">24H points</p>
                  </div>
                  <div className=" col-md-2 col-6 text-end text-md-center ">
                    <p className="text-gray mb-0">11</p>
                  </div>
                  <div className=" col-md-2 col-6 text-start text-md-center d-block d-md-none">
                    <p className="text-gray mb-0">total points</p>
                  </div>
                  <div className="col-md-2 col-6 text-end ">
                    <p className="text-gray mb-0">324</p>
                  </div>
                </div>
              </div>
            </div> */}

            <div className='text-center mt-4'>
              <div className='btn-load bg-gadient mx-auto rounded-4'>
                <div className=" bg-dark-1 btn-load-inner position-relative">
                  <Link to={'/leaderboard'} className="btn btn-default shadow-0 bg-dark-1 mb-0 w-100">
                    <span className="btn-default-text">Load More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <h4 className="text-white py-5">
            invite friends to move up in the <span className="list"> list</span>
          </h4>
          <div className="col-md-6 mx-auto text-white">
            <p className="referral p-0 m-0 ">referral link</p>
            <div className="d-flex border flex-start rounded-3 py-4  px-3">
              <div className="text-start ">https://chainwarz.com/allowlist/?ref={data?.referral_code}</div>
              <img src={copy} alt="" className="cursor-pointer ms-auto" onClick={() => {
                navigator.clipboard.writeText(`https://chainwarz.com/allowlist/?r=${data?.referral_code}`).then(toast.info('Link copied to clipboard !', {
                  toastId: "copy"
                }))
              }} />
            </div>
          </div>
        </div>

        <div className="row text-center text-white py-5">
          <h1 className="py-3">Follow Us</h1>
          <h2>
            Subscribe to our team members for more rewards points and stay tuned
            for the latest news
          </h2>
          <div className="col-lg-6 col-md-8 mx-md-auto mt-5">
            <div className="rounded-4 border d-md-flex  p-3 mb-3 ">
              <div className="me-auto d-flex mb-3  col-md-9 align-items-center">
                <figure className="mb-0 col-2 text-start">
                  <img src={ava1} className="" alt="" />
                </figure>
                <div className="col-10 text-start">  <span>@ChainwarzGaming-1</span></div>
              </div>
              <div className="col-12  col-md-3">

                <div className="btn-load mw-100 bg-gadient mx-auto   rounded-4">
                  <div className=" bg-dark-1 btn-load-inner position-relative">
                    <button className="btn btn-default py-2 shadow-0 text-center px-2 bg-dark-1 mb-0 w-100" onClick={() => {
                      window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
                    }}>
                      <span className="btn-default-text fs-6">FOLLOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-4 border d-md-flex  p-3 mb-3 ">
              <div className="me-auto d-flex mb-3  col-md-9 align-items-center">
                <figure className="mb-0 col-2 text-start">
                  <img src={ava1} className="" alt="" />
                </figure>
                <div className="col-10 text-start">  <span>@ChainwarzGaming-2</span></div>
              </div>
              <div className="col-12  col-md-3">

                <div className="btn-load mw-100 bg-gadient mx-auto   rounded-4">
                  <div className=" bg-dark-1 btn-load-inner position-relative">
                    <button className="btn btn-default py-2 shadow-0 text-center  px-2 bg-dark-1 mb-0 w-100" onClick={() => {
                      window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
                    }}>
                      <span className="btn-default-text fs-6">FOLLOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-4 border d-md-flex  p-3 mb-3 ">
              <div className="me-auto d-flex mb-3  col-md-9 align-items-center">
                <figure className="mb-0 col-2 text-start">
                  <img src={ava1} className="" alt="" />
                </figure>
                <div className="col-10 text-start">  <span>@ChainwarzGaming-3</span></div>
              </div>
              <div className="col-12  col-md-3">

                <div className="btn-load mw-100 bg-gadient mx-auto   rounded-4">
                  <div className=" bg-dark-1 btn-load-inner position-relative">
                    <button className="btn btn-default py-2 shadow-0 text-center  px-2 bg-dark-1 mb-0 w-100" onClick={() => {
                      window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
                    }}>
                      <span className="btn-default-text fs-6">FOLLOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-4 border d-md-flex  p-3 mb-3 ">
              <div className="me-auto d-flex mb-3  col-md-9 align-items-center">
                <figure className="mb-0 col-2 text-start">
                  <img src={ava1} className="" alt="" />
                </figure>
                <div className="col-10 text-start">  <span>@ChainwarzGaming-4</span></div>
              </div>
              <div className="col-12  col-md-3">

                <div className="btn-load mw-100 bg-gadient mx-auto   rounded-4">
                  <div className=" bg-dark-1 btn-load-inner position-relative">
                    <button className="btn btn-default py-2 shadow-0 text-center  px-2 bg-dark-1 mb-0 w-100">
                      <span className="btn-default-text fs-6" onClick={() => {
                        window.open('https://twitter.com/intent/follow?screen_name=ChainwarzGaming')
                      }}>FOLLOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <h3 className="pt-5">
            We appreciate your interest in Chain Warz and can't wait to share
            our exciting platform with you!
          </h3>
          <h3 className="py-2">Best regards,<br /> The Chain Warz Team</h3>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
