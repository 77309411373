import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
    name: 'Commmon',
    initialState: {
        loading: false,
        showAlert: false,
        alertData: {},
        smartWallet: null,
        isWalletConnected: false,
        showConnectModal: false,
        showRefferModal: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setShowAlert: (state, action) => {
            state.showAlert = action.payload;
        },
        setAlertData: (state, action) => {
            state.alertData = action.payload;
        },
        setSmartWallet: (state, action) => {
            state.smartWallet = action.payload;
        },
        showConnectModal: (state, action) => {
            state.showConnectModal = action.payload;
        },
        showRefferModal: (state, action) => {
            state.showRefferModal = action.payload;
        },
        setWalletConnection: (state, action) => {
            state.isWalletConnected = action.payload;
        }
    },
})

export const {
    setLoading,
    setShowAlert,
    setAlertData,
    setSmartWallet,
    showConnectModal,
    showRefferModal,
    setWalletConnection
} = commonSlice.actions;

export default commonSlice.reducer;