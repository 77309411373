import React from 'react';
import './footer.scss';
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoDiscord } from 'react-icons/bi'
// import twitter from "../../assets/images/twitter-1.svg";
// import discord from "../../assets/images/discord.svg";
import { Link } from 'react-router-dom';


const Footer = () => {


    return (
        <footer className='bg-dark-1 '>

            <div className="container py-equal">
                <div className="row mx-0">
                    <div className="col-md-5 mx-auto">
                        <div className="heading-element text-center pb-sm-5 mb-3">
                            <h2 className='text-white text-shadow  text-stroke'>Join US!</h2>
                            <p className='text-white h4 footer-content'>Join our Chain Warz community to stay updated on the latest and greatest battles in blockchain and participate in the action.</p>
                        </div>
                        <div className='social-icons'>
                            <a rel="noreferrer" href='https://twitter.com/intent/follow?screen_name=ChainwarzGaming' target='_blank'>
                                <FaXTwitter fontSize={32} className='twitter' />
                            </a>
                            <a rel="noreferrer" href='https://discord.gg/3syprfcb8r' target='_blank'>
                                <BiLogoDiscord fontSize={32} className='discord' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="terms-condition">
                    <Link to="/policy/#terms" className='mx-2 text-white'>Terms & conditions</Link>
                    <Link to="/policy/#privacy" className='mx-2 text-white'>Privacy Policy</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer;