import Modal from "react-bootstrap/Modal";
import "./WalletModal.css";
import METAMASK_ICON from "../../assets/images/Metamask.png";
import COINBASE_ICON from "../../assets/images/Coinbase.png";
import WALLETCONNECT_ICON from "../../assets/images/WalletConnect.png";
import {
  coinbaseWallet,
  metamaskWallet,
  useConnect,
  walletConnect,
} from "@thirdweb-dev/react";
import { WalletConnect } from "@thirdweb-dev/wallets";
import { toast } from "react-toastify";

function WalletModal({ setShow, show }) {
  const wallet = new WalletConnect();
  const wallets = ["Metamask", "Coinbase"];
  const walletsConfig = [metamaskWallet(), coinbaseWallet()];
  const walletsIcon = [METAMASK_ICON, COINBASE_ICON];
  const connect = useConnect();
  const handleConnect = async (config) => {
    setShow(false);
    try {
      await connect(config);
      setShow(false);
    } catch (error) {
      console.error("Error connecting:", error);
      toast.error('Error connecting !', {
        toastId: "error"
      })
    }
  };

  const handleWConnect = async (config) => {
    setShow(false);
    try {
      if (config === walletConnect()) {
        const wallet = new WalletConnect();
        await wallet.connect();
      } else {
        await connect(config);
      }
      setShow(false);
    } catch (error) {
      console.error("Error connecting:", error);
      toast.error('Error connecting !', {
        toastId: "error"
      })
    }
  };
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="walletList d-flex align-Items-center">
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <>
            <h3 className="text-white text-center">CONNECT</h3>
            <p className="w-50 text-white mx-auto my-3 text-center">
              Choose a wallet connection method
            </p>
            <div className="walletbutton d-grid">
              {wallets.map((wallet, index) => (
                <button
                  key={index}
                  onClick={() => handleConnect(walletsConfig[index])}
                >
                  <img src={walletsIcon[index]} width={90} alt="" />
                  {wallet}
                </button>
              ))}
              <button onClick={() => handleWConnect(wallet.connect())}><img src={WALLETCONNECT_ICON} width={90} alt="" />
                WalletConnect
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WalletModal;
