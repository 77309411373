import { createSlice } from '@reduxjs/toolkit';

const loginSlice = createSlice({
    name: 'User',
    initialState: {
        userId: '',
        tier: 1,
        image: '',
        email: '',
        points: 0,
        username: '',
        isLogin: false,
        joinedDate: '',
        walletName: '',
        walletAddress: '',
        isRegistered: false,
        referral_code: null,
        twitterUserName: "",
        discordUserName: "",
        isTwitterVerified: false,
        isDiscordVerified: false
    },
    reducers: {
        login: (state, action) => {
            state.isLogin = true;
            state.userId = action.payload._id;
            state.tier = action.payload.tier;
            state.points = action.payload.points;
            state.walletName = action.payload.walletName;
            state.referral_code = action.payload.referral_code;
            state.isRegistered = action.payload.isRegistered;
            state.isTwitterVerified = action.payload.isTwitterVerified;
            state.twitterUserName = action.payload.twitterUserName;
            state.discordUserName = action.payload.discordUserName;
            state.isDiscordVerified = action.payload.isDiscordVerified;
            state.walletAddress = action.payload.walletAddress;
            state.accessToken = action.payload.token;
            state.email = action.payload.email;
            state.username = action.payload.userName;
            state.joinedDate = action.payload.createdAt;
            state.image = action.payload?.profile;
        },
        setData: (state, action) => {
            state.isRegistered = true;
            state.email = action.payload.email;
            state.username = action.payload.userName;
        },
        logOut: (state) => {
            state.tier = 1;
            state.userId = "";
            state.points = 0;
            state.image = "";
            state.email = '';
            state.username = '';
            state.isLogin = false;
            state.joinedDate = "";
            state.walletName = "";
            state.walletAddress = "";
            state.referral_code = null;
            state.isRegistered = false;
        }
    },
})

export const { login, logOut, setData } = loginSlice.actions
export default loginSlice.reducer;