import moment from 'moment';

export const getImageSrc = (image_name='l46rapt128chewbcca.png') => {
    return `https://api.chainwarz.com/uploads/${image_name}`
    // return `http://localhost:4401/uploads/${image_name}`
}

export const downloadCSV = (data, filename) => {
    const url = URL.createObjectURL(new Blob([data], { type: `text/csv` }));
    const a = document.createElement('a')
    a.href = url;
    a.setAttribute('download', `${filename}_${Date.now()}.csv`)
    a.click()
}

export const fullDateFormat = (date) => {
    return (
        <>
            {moment(date).format('MMMM DD, YYYY')}
            <br />
            <small>{moment(date).format('h:mm a')}</small>
        </>
    )
}
export const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

export function showSlicedText(text) {
    return text.length > 12 ? [text?.slice(0, 4), text.slice(text?.length - 4)].join('...') : text;
}

export function scrollIntoView(hash) {
    if (hash) {
        let elem = document.getElementById(hash.slice(1))
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth" });
        }
    } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
}

export const chainId = 11155111;