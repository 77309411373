/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import AllowlistStepTwo from "./allowliststeptwo.component";
import AllowlistStepOne from "./allowliststepone.component";
import Thankyou from "./thankyou.component";
import { scrollIntoView } from "../../utils/common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { post } from "../../utils/api/apiHelper";
import { STORAGE_KEYS } from "../../config";

const Allowlist = () => {
    const redirect = useNavigate();
    const [step, setStep] = useState(1);
    const [data, setData] = useState([
        {
            question: "How did you hear about us?",
            answer: ""
        },
        {
            question: "Which features of Chain Warz excite you the most, and why?",
            answer: ""
        },
        {
            question: "Could you tell us about the criteria you use to evaluate a new project as an investment opportunity?",
            answer: ""
        },
        {
            question: "Given those criteria, what are your thoughts on Chain Warz as a potential long-term asset?",
            answer: ""
        },
        {
            question: "What type of utility do you value in a project?",
            answer: ""
        },
        {
            question: "Could you share instances of projects you've retained post mint, and tell us what fueled your conviction in them?",
            answer: ""
        },
        {
            question: "Name one project you are proud to have invested in, and tell us why.",
            answer: ""
        },
        {
            question: "Can you share a personal story of your dedication and long-term commitment to a project or cause?",
            answer: ""
        },
        {
            question: "Outside of web3, what do you do for a living?",
            answer: ""
        },
        {
            question: "Do you have any work experience in web2, if so, could you tell us about it?",
            answer: ""
        },
        {
            question: "What do you do for fun and what are your hobbies?",
            answer: ""
        },
        {
            question: "Why do you consider yourself worthy of making it to our allow list?",
            answer: ""
        },
        {
            question: "We'd love to hear more about you - could you share a brief introduction?",
            answer: ""
        },
        {
            question: "Could you provide us with the link to your tweet about applying for our allow list?",
            answer: ""
        }
    ]);
    const authenticateDiscord = async () => {
        let loadingToast;
        try {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            if (code) {
                loadingToast = toast.loading("Connecting Discord ...");
                let response = await post('/client/authenticate_discord', {
                    redirect_uri: `${window.location.origin}/allowlist`,
                    code
                });
                redirect('/allowlist', {
                    replace: true
                });
                window.open('https://discord.gg/3syprfcb8r')
                localStorage.setItem(STORAGE_KEYS.discord, JSON.stringify(response.data.creds));
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed To Connect !", {
                toastId: "error"
            });
        }
        finally {
            toast.done(loadingToast);
        }
    }
    useEffect(() => {
        setStep(1);
    }, []);
    useEffect(() => {
        authenticateDiscord()
    }, [])
    useEffect(() => {
        scrollIntoView();
    }, [step]);

    switch (step) {
        case 1: return <AllowlistStepOne setStep={setStep} />
        case 2: return <AllowlistStepTwo setStep={setStep} setData={setData} data={data} />
        case 3: return <Thankyou setStep={setStep} />
        default: return <AllowlistStepOne setStep={setStep} />
    }
};

export default Allowlist;
