import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store';
import { PubNubProvider } from 'pubnub-react';
import pubnub from './utils/pubnub';
import { ThirdwebProvider, coinbaseWallet, en, metamaskWallet, walletConnect } from "@thirdweb-dev/react";
import { Sepolia } from "@thirdweb-dev/chains";
import config from './config.json'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PubNubProvider client={pubnub}>
    <ThirdwebProvider
      clientId={config.REACT_PUBLIC_TEMPLATE_CLIENT_ID}
      activeChain={Sepolia}
      autoConnect={true}
      locale={en()}
      supportedChains={[
        Sepolia
      ]}
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet(),
        walletConnect()
      ]}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ThirdwebProvider>
  </PubNubProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
