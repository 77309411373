import { Chat } from "@pubnub/chat"
import config from '../config.json'

export default class PubNubChat {
    async init(userId, channelId) {
        console.log({
            publishKey: config.REACT_APP_PUBNUB_PUB_KEY,
            subscribeKey: config.REACT_APP_PUBNUB_SUB_KEY,
            userId,
        }, "keys");
        this.pubnubChat = await Chat.init({
            publishKey: config.REACT_APP_PUBNUB_PUB_KEY,
            subscribeKey: config.REACT_APP_PUBNUB_SUB_KEY,
            userId,
        })
        this.channelId = channelId;
        await this.getChannelDetails();
    }

    async getChannelDetails(users = []) {
        if (!this.channel) {
            const channelDetails = await this.pubnubChat.getChannel(this.channelId);
            if (!channelDetails) {
                const { channel } = await this.createGroupConversation(users);
                this.channel = channel;
            } else {
                this.channel = channelDetails;
            }
        }
    }

    async sendMessage(text, user) {
        await this.getChannelDetails([user]);
        const response = await this.channel.sendText(text,
            {
                storeInHistory: true,
                meta: {
                    user,
                },
            });
        return response;
    }

    async getMessageHistory(users, timetoken) {
        await this.getChannelDetails(users);
        // await new Promise((resolve) =>  setTimeout(() =>  resolve(), 3000));
        const { messages } = await this.channel.getHistory({
            startTimetoken: timetoken ? (Number(timetoken) - 1).toString() : undefined,
        });
        return messages;
    }

    async getMessage(users, timetoken) {
        await this.getChannelDetails(users);
        const message = await this.channel.getMessage(timetoken);
        return message;
    }

    async joinChannel(users) {
        await this.getChannelDetails(users);
        await this.channel.join();
    }


    async createGroupConversation(users) {
        const { channel } = await this.pubnubChat.createGroupConversation({
            users,
            channelId: this.channelId,
        });
        return channel;
    }
}