import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import './confirmpopup.scss'
const ConfirmPopup = () => {
    const { showAlert: show, alertData: data, } = useSelector((state) => state.common);

    return (
        <Modal size="sm" show={show} aria-labelledby="example-modal-sizes-title-sm" centered className="confirm-popup">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                    {data.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{
                data?.buttons?.map((btn, i) => {
                    return <button className="buttons" key={i} onClick={btn.onClick}>{btn?.label}</button>
                })
            }
            </Modal.Body>
        </Modal>)
}
export default ConfirmPopup;