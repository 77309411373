import React, { useId } from "react";
import "./editprofile.scss"
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Otpverify from "../../components/modal/verifyotpmodal.component";
import { setLoading } from "../../redux/slices/commonSlice";
import { post, postAsForm } from "../../utils/api/apiHelper";
import { ImCross } from "react-icons/im";
import { getImageSrc } from "../../utils/common";
import { FiEdit2 } from 'react-icons/fi';
import { login } from "../../redux/slices/userSlice";
import { fireToast, toastConfirm } from "../../utils/helper";

const Editprofile = () => {
  let fileId = useId();
  const user = useSelector((state) => state.user);
  const [modalShow, setModalShow] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [Image, setImage] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFileSelect = (e) => {
    let image = e.target.files[0];
    if (image) {
      setImage(image);
    }
  };

  const sendEmail = async (email, referredByCode) => {
    await post('/client/verify-email', {
      email: email,
      referredByCode: referredByCode
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response?.message);
          setModalShow(true)
        } else {
          toast.error(response?.error ?? "Something went wrong.", {
            toastId: "error"
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      })
  }
  const changedValues = {};

  const submitForm = async (values) => {
    console.log(values, 'values');
   
    dispatch(setLoading(true));
    const formData = new FormData();
    Object.entries(changedValues).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // formData.append("referredByCode", values.referredByCode);
    // formData.append("userName", values.userName);
    // formData.append("email", values.email);
    // formData.append('profileImage', Image);
    const response = await postAsForm('/client/updateProfile', formData);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(login({ ...response.data }));
      toast.success(response?.message);
      setModalShow(true)
      // navigate('/profile');
    } else {
      toast.error(response?.error ?? "Something went wrong.", {
        toastId: "error"
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      referredByCode: "",
      email: user.email ?? "",
      userName: user.username ?? "",
    },
    validationSchema: Yup.object({
      // eslint-disable-next-line no-useless-escape
      email: Yup.string().email("please provide valid email address.").matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "please provide valid email address.").required("email is required."),
      userName: Yup.string()
        .required("username is required.")
        .min(6, "username is too short - should be 6 chars minimum.")
    }),
    onSubmit: async (values) => {
      try {
        console.log(user.username ,values.userName,"testing >>>>>>>>>>>");
        if(user.username !=  values.userName){
          toastConfirm("Are you sure?", `Changing your username alters your referral ID. Use the new one to earn points!`)
          .fire()
          .then(async ({ isConfirmed }) => {
            if (isConfirmed) {
            setFormData(values)
            dispatch(setLoading(true));
            if (user?.isRegistered) {
              await submitForm(values)
              return;
            } else {
              await sendEmail(values.email, values.referredByCode)
              dispatch(setLoading(false));
              return;
            }
            }
          }) .catch((err) => {
            console.log(err, "err");
            fireToast("error", err?.response?.data?.error);
          });
        }else{
          setFormData(values)
          dispatch(setLoading(true));
          if (user?.isRegistered) {
            await submitForm(values)
            return;
          } else {
            await sendEmail(values.email, values.referredByCode)
            dispatch(setLoading(false));
            return;
          }
        }
      //   toastConfirm("Are you sure?", `You want to change username?`)
      // .fire()
      // .then(async ({ isConfirmed }) => {
      //   if (isConfirmed) {
      //   setFormData(values)
      //   dispatch(setLoading(true));
      //   if (user?.isRegistered) {
      //     await submitForm(values)
      //     return;
      //   } else {
      //     await sendEmail(values.email, values.referredByCode)
      //     dispatch(setLoading(false));
      //     return;
      //   }
      //   }
      // })
      } catch (error) {
        dispatch(setLoading(false));
        console.log(error);
        toast.error('Failed To Login!', {
          toastId: "error"
        });
      }
    }
  });
  Object.keys(formik.values).forEach(key => {
    if (formik.values[key] != formik.initialValues[key]) {
      changedValues[key] = formik.values[key];
    }
  });
  if (Image != null) {
    changedValues['profileImage'] = Image;
  }
  return (
    <div className=" pt-head bg-dark-1 text-center">
      <div className="edit-profile">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          id={fileId}
          hidden={true}
        />
        <figure className="upload-figure img-background rounded-4 position-relative mx-auto text-center">
          {
            Image
              ?
              <React.Fragment>
                <div className='remove bg-gadient' onClick={() => {
                  setImage(null);
                  document.getElementById(fileId).value = null;
                }}>
                  <ImCross fontSize={8} />
                </div>

                <label htmlFor={fileId}>
                  <div className="file mx-auto bg-gadient position-relative rounded-2">
                    <img
                      src={URL.createObjectURL(Image)}
                      alt=""
                      className="rounded-2 w-100 h-100 object-fit-cover"
                    />
                  </div>
                </label>
              </React.Fragment>
              : (
                <label htmlFor={fileId}>
                  <div className="file  mx-auto bg-gadient position-relative rounded-2">
                    <FiEdit2 className="h5 text-white position-absolute edit_icon m-2 cursor-pointer" />
                    {user?.image && <img src={getImageSrc(user?.image)} alt="" className="rounded-2 w-100 h-100 object-fit-cover" />}
                  </div>
                </label>
              )
          }

        </figure>


        <form onSubmit={formik.handleSubmit}>
          <div className="mt-5">
            <div className="form-group mb-3">
              <label className="text-white">Nickname</label>
              <input name="userName" placeholder="Enter Username" type="text" className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <p className="text-danger">
                  {formik.errors.userName}
                </p>
              ) : null}
            </div>
            <div className="form-group">
              <label className="text-white">E-mail</label>
              <input placeholder="Enter Email" type="email" className="form-control bg-transparent"
                name='email'
                // disabled={user?.isRegistered}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-danger">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            {
              !user?.isRegistered && <div className="form-group mt-2">
                <label className="text-white">Referral Code</label>
                <input placeholder="Enter referral code " type="text" className="form-control"
                  name='referredByCode'
                  disabled={user?.isRegistered}
                  hidden={user?.isRegistered}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.referredByCode}
                />
              </div>
            }
            <div className="d-flex py-3 align-items-center">
            
            </div>
            <button className="cursor-pointer btn btn-default py-4 w-100"><span className="text-gradient">Save</span> </button>
          </div>
        </form>
      </div>
      {modalShow && <Otpverify show={modalShow} onHide={() => setModalShow(false)} submitForm={submitForm} sendEmail={sendEmail} formData={formData} />}
    </div>
  )
}


export default Editprofile;
