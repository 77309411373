import React from 'react'
import '../policy/policy.scss';
import { Link } from 'react-router-dom';


function Policycomponents() {
  return (
    <div className='wrapper pt-banner bg-dark-1'>

      <div className='container'>


        <div className="row ">
          <div className="col-md-3  ">
            <div className='navigation policy-sidebar'>
              <p className='navi'>NAVIGATION</p>
              <Link to='/policy/#terms' className='terms1 text-white' >1.TERMS AND CONDITIONS</Link>
              <Link to="/policy/#privacy" ><p className='privacy1 mt-3 text-white' >2.PRIVACY POLICY</p> </Link>
            </div>

          </div>




          <div className="  col-md-9 ">

            <h2 className='terms text-white' id='terms'>1.TERMS AND CONDITIONS </h2>
            <div className='inner-term'>
              <p> Welcome to Chain Warz. These Terms and Conditions (the "Agreement") govern your use of the Chain Warz website (the "Site") and any games, products, and services offered on the Site (collectively, the "Service").</p>
              <p>1. Acceptance of Terms. By accessing or using the Service, you agree to be bound by this Agreement. If you do not agree to this Agreement, you may not access or use the Service.</p>
              <p>2. Eligibility. You must be at least 18 years old to access or use the Service. By accessing or using the Service, you represent and warrant that you are at least 18 years old.</p>
              <p>3. Account Creation. In order to use the Service, you must create an account on the Site. You must provide accurate and complete information when creating your account. You are responsible for maintaining the confidentiality of your account login credentials.</p>
              <p>4. Ownership of Content. All content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of Chain Warz or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use or reproduce any content on the Site without our express written consent.</p>
              <p>5. User Conduct. You agree to use the Service in accordance with all applicable laws and regulations. You will not use the Service to engage in any unlawful, fraudulent, or abusive conduct. You will not upload, transmit, or distribute any content that is defamatory, obscene, or otherwise offensive.</p>
              <p>6. Prohibited Users. Users from certain jurisdictions may not be permitted to use the Service due to local laws or regulations. It is your responsibility to ensure that your use of the Service is legal in your jurisdiction.</p>
              <p>7. No Warranties. The Service is provided "as is" without warranty of any kind. We do not guarantee that the Service will be error-free or uninterrupted, and we are not responsible for any loss or damage resulting from the use of the Service.</p>
              <p>8. Limitation of Liability. In no event shall Chain Warz be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Service.</p>
              <p>9. Changes to the Service. Chain Warz reserves the right to modify or discontinue the Service at any time without notice.</p>
              <p>10. Changes to this Agreement. We may modify this Agreement at any time by posting the revised Agreement on the Site. Your continued use of the Service following the posting of any changes to this Agreement constitutes your acceptance of those changes.</p>
              <p>11. Governing Law. This Agreement shall be governed by and construed in accordance with the laws of Curaçao. Any disputes arising out of this Agreement shall be resolved through binding arbitration in Curaçao.</p>
              <p>12. Contact Us. If you have any questions about this Agreement, please contact us at chainwarz@proton.me</p>
            </div>

            <p className='terms text-white' id="privacy">2.PRIVACY POLICY </p>
            <div className='inner-term'>

              <p className='text-white fs-4'>Introduction</p>
              <p>Chain Warz, operated by Chain Warz Gaming N.V. in Curaçao, respects the privacy of its users and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit the website chainwarz.com and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>

              <p className='text-white fs-4'>Information We Collect</p>
              <p>We may collect personal identification information from users including but not limited to Ethereum wallet address, IP address, age consent, email address, Twitter handle, and Discord ID. We collect this information when users register on our website.</p>

              <p className='text-white fs-4'>Use of Information</p>
              <p>We collect personal information to help with our anti-money laundering protocols and Know Your Customer (KYC) procedures that are necessary to maintain our gaming license. We also use the information to ensure that users are not breaking our rules and for our own personal marketing email campaigns.</p>

              <p className='text-white fs-4'>Sharing of Information</p>
              <p>We do not share any user information with anyone, unless required by law or regulation, or other legal subpoena or warrant.</p>

              <p className='text-white fs-4'>Security of Information</p>
              <p>We have access control lists to ensure all data can be accessed by company employees who have the defined roles that permit them to have access to the data. These are usually our admins. At the same time, we keep user information like emails encrypted.</p>

              <p className='text-white fs-4'>Retention of Information</p>
              <p>We keep the user information as long as the user is active on our website. But if they are not active for more than a year we no longer keep that information.</p>

              <p className='text-white fs-4'>Right to Removal of Information</p>
              <p>Users have the right to deprovision off our system so that we no longer have access to their information. At the same time, users are allowed to remove their Twitter and Discord IDs by disconnecting the respective platform from our website.</p>

              <p className='text-white fs-4'>Use of Cookies and Tracking Technologies </p>
              <p>Just like any best practices website does when it comes to handling cookies and tracking technologies. The only thing we track is the IP Address, as some jurisdictions are not allowed on our website based on the gambling license rules. </p>

              <p className='text-white fs-4'>Changes to Our Privacy Policy </p>
              <p>We send an email to all registered users that privacy policies have been updated, and we notify the user on their profile screen as well.  </p>

              <p className='text-white fs-4'>Contacting Us </p>
              <p>Users can reach us by email, Twitter, and Discord by opening a support ticket.</p>
            </div>
          </div>


        </div>


      </div>



    </div>

  )
}

export default Policycomponents;


