import '../assets/scss/loader.scss';
import { Modal } from "react-bootstrap";
// import { Blocks } from "react-loader-spinner";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";


LoadingOverlay.propTypes = undefined;
function Spinner() {
  return (
    <div className="custom-loader"></div>
    // <Blocks
    //   visible={true}
    //   height="80"
    //   width="80"
    //   ariaLabel="blocks-loading"
    //   wrapperStyle={{}}
    //   wrapperClass="blocks-wrapper"
    // />  
  );
}

function Loader() {

  const show = useSelector((state) => state.common.loading);

  return (
    <>
      <Modal centered className="loader" show={show}>
        <LoadingOverlay dingOverlay active={true} spinner={<Spinner />} />
      </Modal>
    </>
  );
}

export default Loader;
