import React from "react";
import Tab from "react-bootstrap/Tab";
import './battle.scss'

import { Nav } from "react-bootstrap";
import vcup from "../../assets/images/vcup.png";
import Jackpot from "./jackpot.component";
import UpcomingBattle from "./upcomingbattle.component";
import { Link } from "react-router-dom";

const Battles = () => {
  return (
    <div className="wrapper ">
      <div className="container  ">
        <div className="heading-element pb-4">
          <h2 className='text-white text-shadow text-center text-stroke'>Battles</h2>
        </div>

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="row text-md-start text-center mb-3">
            <Nav variant="pills col-6 col-md-9" className="">
              <Nav.Item>
                <Nav.Link eventKey="first" className="upcome">Arena</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Boss fight</Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="col-6 col-md-3 text-end">
              <Link to="/winners" className="ms-auto me-auto me-md-0 btn btn-primary  mt-md-0  rounded-5 btn-gadient  p-3"  >
                <img src={vcup} width={20} height={20} alt="" className='me-2' />
                <span> Winners</span>
              </Link>
            </div>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="first"> <UpcomingBattle />  </Tab.Pane>
            <Tab.Pane eventKey="second"><Jackpot /></Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Battles;
