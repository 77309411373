import React, { useCallback, useEffect, useState } from 'react'
// import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import './winner.scss';
// import ava1 from "../../assets/images/Ava.svg"
// import ava2 from "../../assets/images/ava2.svg"
// import ava3 from "../../assets/images/ava3.svg"
// import ava4 from "../../assets/images/Ava4.svg"
// import ava5 from "../../assets/images/Ava5.svg"
import eth from "../..//assets/images/ethlogo.svg";
// import sharelogo from '../../assets/images/share.svg'
// import sort from "../../assets/images/sortbtn.svg"
import { FcGenericSortingAsc, FcGenericSortingDesc } from 'react-icons/fc';
import { get } from '../../utils/api/apiHelper';
import { toast } from 'react-toastify';
import PaginationComponent from '../Pagination';
import { fullDateFormat, getImageSrc } from '../../utils/common';
import { Placeholder } from 'react-bootstrap';

const Winner = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [isFetching, setFetching] = useState(false);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState({
        sortColumn: "createdAt",
        sortBy: -1
    });
    const getAllWinners = useCallback(async () => {
        try {
            setFetching(true)
            let response = await get(`/client/get_winners?pageNumber=${currentPage}&pageSize=${perPage}&sortColumn=${sort['sortColumn']}&sortBy=${sort['sortBy']}`);
            if (response.status === 200) {
                console.log(response.data.items, 'response.data.items');
                setItems(response.data.items);
                setTotal(response.data?.totalItems ?? 0);
                setPerPage(response.data.pageSize);
                // } else {
                //     toast.error('Failed to fetch data!');
            }
            setFetching(false)
        } catch (error) {
            toast.error('Something went wrong!', {
                toastId: "error"
            });
            setFetching(false)
        }
    }, [currentPage, perPage, sort]);
    useEffect(() => {
        getAllWinners();
    }, [getAllWinners])


    return (
        <div className='wrap'>
            <div className='container'>
                <div className="heading-element ">
                    <h2 className='text-white text-shadow text-center text-stroke'>Winners</h2>
                </div>
                <div className='float-end pb-4'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className='text-light  drop-btn'>
                            {sort.sortBy === 1 ?
                                <FcGenericSortingAsc className='text-light fcicon' />
                                :
                                <FcGenericSortingDesc className='text-light' />
                            }
                            <span className='ms-1'>
                                {
                                    sort.sortColumn === 'createdAt' && sort.sortBy === 1 && 'oldest'
                                }
                                {
                                    sort.sortColumn === 'createdAt' && sort.sortBy === -1 && 'newest'
                                }
                                {
                                    sort.sortColumn === 'amount_wonned' && sort.sortBy === -1 && 'highest win'
                                }
                                {
                                    sort.sortColumn === 'amount_wonned' && sort.sortBy === 1 && 'lowest win'
                                }
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            <Dropdown.Item href="#" onClick={() => setSort({
                                sortColumn: "createdAt",
                                sortBy: -1
                            })}>newest</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setSort({
                                sortColumn: "createdAt",
                                sortBy: 1
                            })}>oldest</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setSort({
                                sortColumn: "amount_wonned",
                                sortBy: -1
                            })}>highest win</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setSort({
                                sortColumn: "amount_wonned",
                                sortBy: 1
                            })}>lowest win</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='pt-5'>
                    <div className="winner mt-5">
                        <div className="row mx-0 py-4">
                            <div className=" col-6 col-md-2">
                                <p className="text-gray mb-0">winner</p>
                            </div>
                            <div className=" col-6 col-md-2 text-center">
                                <p className="text-gray mb-0">prize pool</p>
                            </div>
                            <div className="col-md-2 text-center d-none d-md-block">
                                <p className="text-gray mb-0"> fighters</p>
                            </div>
                            <div className="col-md-3 text-center d-none d-md-block">
                                <p className="text-gray mb-0">battle hash</p>
                            </div>
                            <div className="col-md-3 text-end d-none d-md-block">
                                <p className="text-gray mb-0">date time</p>
                            </div>
                        </div>
                        <div>
                            {

                                total <= 0 && !isFetching &&
                                <p className="text-warning text-center fs-5">
                                    No Data Found !
                                </p>
                            }
                            {
                                isFetching ? Array(10).fill(null).map((_, i) => {
                                    return (<div className="bg-light battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix" key={i}>
                                        <div className="bg-dark-1 rounded-4">

                                            <div className="row mx-0 align-items-center py-3 ">

                                                <div className="col-6 col-md-2 mb-3 mb-md-0">
                                                    <Placeholder xs={6} bg='light' />
                                                </div>


                                                <div className=" col-6 col-md-2 text-end text-md-center mb-3 mb-md-0 text-white">
                                                    <Placeholder xs={6} bg='light' />
                                                </div>

                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">Fighters</p>
                                                </div>
                                                <div className="col-6 col-md-2 winner-sec text-end text-md-center mb-3 mb-md-0 text-white">
                                                    <Placeholder xs={6} bg='light' />
                                                </div>
                                                <div className="col-6 d-md-none mb-3 mb-md-0">
                                                    <p className="text-gray mb-0">battle hash</p>
                                                </div>
                                                <div className="col-md-3 col-6 text-end text-md-center mb-3 mb-md-0 text-white">
                                                    <Placeholder xs={6} bg='light' />
                                                </div>
                                                <div className="col-md-3 text-md-end text-center pt-5 pt-md-0 text-white">
                                                    <Placeholder xs={6} bg='light' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                                    :
                                    total > 0 && items?.map((data) => {
                                        return (
                                            <div className="bg-light battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix" key={data?._id}>
                                                <div className="bg-dark-1 rounded-4">

                                                    <div className="row mx-0 align-items-center py-3 ">

                                                        <div className="col-6 col-md-2 mb-3 mb-md-0 text-white">
                                                            <img className='pic-box' src={getImageSrc(data?.user?.profile)} alt="" />
                                                            <span>{data?.user?.userName}</span>
                                                        </div>


                                                        <div className=" col-6 col-md-2 text-end text-md-center mb-3 mb-md-0">
                                                            <div className=" winner-sec ">
                                                                <p className="text-white ">  <img src={eth} alt='' /> {data?.amount_wonned?.toFixed(4)} eth</p>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 d-md-none mb-3 mb-md-0">
                                                            <p className="text-gray mb-0">Fighters</p>
                                                        </div>
                                                        <div className="col-6 col-md-2 winner-sec text-end text-md-center mb-3 mb-md-0">
                                                            <p className="text-white ">{data?.battle?.total_tickets_sold}</p>
                                                        </div>
                                                        <div className="col-6 d-md-none mb-3 mb-md-0">
                                                            <p className="text-gray mb-0">battle hash</p>
                                                        </div>
                                                        <div className="col-md-3 col-6 text-end text-md-center mb-3 mb-md-0">
                                                            <p className="text-cyan d-inline-block text-truncate text-elips">{data?.battle?.contractAddress}</p>
                                                        </div>
                                                        <div className="col-md-3 text-md-end text-center pt-5 pt-md-0">
                                                            <p className="text-gray cursor-pointer">{fullDateFormat(data?.createdAt)}
                                                                {/* <img src={sharelogo} alt='' /> */}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                        </div>
                    </div>
                </div>
                <div className='text-center mt-5'>
                    <div className="pb-4 mt-4 ">
                        {total > 0 && (
                            <PaginationComponent
                                className="mx-auto"
                                itemsCount={total}
                                itemsPerPage={perPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Winner