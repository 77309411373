import React from 'react';
import './home.scss';
import banner from '../../assets/images/banner.png';
import work1 from '../../assets/images/PickBattle3.png';
import work2 from '../../assets/images/SelectFighter2.png';
import work3 from '../../assets/images/landingPagePrizePool.png';
// import chart from '../../assets/images/chart.png';
// import team1 from '../../assets/images/dreamer.png';
// import team2 from '../../assets/images/roro.png';
// import team3 from '../../assets/images/cookie.png';
// import team4 from '../../assets/images/chewbcca.png';
// import team5 from '../../assets/images/khalanayak.png';
// import team6 from '../../assets/images/samuel.png';
// import team7 from '../../assets/images/jackson.png';
// import team8 from '../../assets/images/beegoe.png';
import robot from '../../assets/images/robot.png';
// import client1 from '../../assets/images/i-tech.png';
import client2 from '../../assets/images/certi.png';
// import client3 from '../../assets/images/hect.png';
// import staticsicon from '../../assets/images/statics-icon.svg';
// import twitter from '../../assets/images/twitter.svg';
import Countdown from 'react-countdown';
import Accordion from 'react-bootstrap/Accordion';
// import PieChart from '../../assets/charts/chart.component';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { post } from '../../utils/api/apiHelper';

const Home = () => {
  // const renderer = ({ hours, minutes, seconds }) => {
  //   return <span>{hours}:{minutes}:{seconds}</span>;
  // };
  const renderer = ({ days, hours, minutes, seconds }) => {
    return <span>{(days * 24) + (+hours)}:{minutes}:{seconds}</span>;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("please provide valid email address.").required("email is required."),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await post('/client/subscription', values);
        if (response.status === 200) {
          toast.success('Email Subscribed Successfully!');
          resetForm();
        } else {
          toast.error(response?.error ?? "Something went wrong.",{
            toastId:"error"
          });
        }
      } catch (error) {
        toast.error('Failed To Subscribe!',{
          toastId:"error"
        });
      }
    }
  });



  return (
    <>
      <section className='banner-area bg-gadient'>
        <div className='banner-area-inner'>
          <p className='banner-area-top-heading banner-area-heading-stroke'>WELCOME TO</p>
          <h2 className='banner-area-large-heading banner-area-heading-stroke'>CHAIN WARZ</h2>
          <img src={banner} alt="banner" className='img-fluid ' />
          {/* <img src={banner_mb} alt="banner" className='img-fluid d-block d-sm-none' /> */}
          <h4 className='mt-0 py-0 d-none d-sm-block'>the ultimate blockchain battle, <br />Where you have a chance to win big!</h4>
          <a href='/battles' target="_blank" className='btn btn-default'><span className='btn-default-text'>Battle Now</span></a>
        </div>
      </section>

      <section className='bg-dark-1 py-equal'>
        <div className="container">
          <div className="heading-element text-center mb-5 pb-3">
            {/* <Link to={} */}
            <h2 className='text-white text-shadow text-stroke' id="howitworks">How It Works</h2>
          </div>
          <div className="row align-items-center mb-5 pb-3">
            <div className="col-md-6">
              <figure className='mb-0 rounded-4 overflow-hidden position-relative d-inline-block'>
                <img src={work1} alt="" />
              </figure>
            </div>
            <div className="col-md-6">
              <div className='howe-content-block '>
                <h4 className='text-white d-flex align-items-center justify-content-center rounded-4 '>1</h4>
                <h3 className='text-white text-shadow mt-md-3 text-stroke'>Join battle</h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 pb-3">
            <div className="col-md-6">
              <figure className='mb-0 rounded-4 overflow-hidden position-relative d-inline-block'>
                <img src={work2} alt="" />
              </figure>
            </div>
            <div className="col-md-6">
              <div className='howe-content-block'>
                <h4 className='text-white d-flex align-items-center justify-content-center rounded-4 '>2</h4>
                <h3 className='text-white text-shadow mt-md-3 text-stroke'>Purchase <br />Fighters</h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 pb-3">
            <div className="col-md-6">
              <figure className='mb-0 rounded-4 overflow-hidden position-relative d-inline-block'>
                <img src={work3} alt="" />
              </figure>
            </div>
            <div className="col-md-6">
              <div className='howe-content-block'>
                <h4 className='text-white d-flex align-items-center justify-content-center rounded-4 '>3</h4>
                <h3 className='text-white text-shadow mt-md-3 text-stroke'>LAST ONE <br /> STANDING TAKES<br /> ALL</h3>
              </div>
            </div>
          </div>
          <div className="d-block text-center countdown">
            <a href='/battles' target="_blank" className='btn btn-default' ><span className='btn-default-text'>Join Battle</span></a>
            <div className='countdown-outer bg-gadient mx-auto rounded-4'>
              <div className="countdown-inner bg-dark-1 rounded-3 position-relative">
                {/* <Countdown date={Date.now() + 60000000} /> */}
                <Countdown date={Date.now() + 60000000} renderer={renderer}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className='py-equal bg-dark-1'>
                <div className="container">
                    <div className="heading-element text-center mb-5 pb-3">
                        <h2 className='text-white text-shadow text-stroke' id='statistics'>Statistics</h2>
                    </div>
                    <div className='container'>
                        <div className="bg-gadient  statistics ">
                            <div className="row ">
                                <div className="col-md-6">
                                    <div className='statistics-content text-white'>
                                        <h4>connected wallets</h4>
                                        <h3>14,273</h3>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='statistics-content text-white'>
                                        <h4>battles played</h4>
                                        <h3>173</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className='col-md-12 banner-area-inner'>
                                    <figure>
                                        <img src={staticsicon} alt="" />
                                    </figure>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <div className='statistics-content text-white'>
                                        <h4>ETH won</h4>
                                        <h3>145,273</h3>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='statistics-content text-white'>
                                        <h4>winners</h4>
                                        <h3>194,273</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 


            </section> */}
      {/* <section className='py-equal bg-dark-1'>
                <div className="container">
                    <div className="heading-element text-center">
                        <h2 className='text-white text-shadow d-none d-sm-block'>Prize Pool tokenomics</h2>
                        <h2 className='text-white text-shadow d-block d-sm-none'> tokenomics</h2>
                    </div>
                    <div className="row winning position-relative">
                        <div className="col-md-12 ">
                            {/* <figure className='text-center'><img src={chart} alt='' /></figure> }
                            <div className='chartbox'>
                            <PieChart/>
                            </div>
                            <div className='winning-percentage'>
                                <h4 className='text-shadow text-stroke'>80%</h4>
                                <h5>Winner</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section className='about bg-dark-1 pb-5'>
        <div className='about-element py-equal'>

          <div className="container">
            <div className="heading-element ">
              <h2 className='text-white text-shadow text-center text-stroke' id="aboutus">About Chainwarz</h2>
              {/* <div className='heading-element-inner text-sm-center'><h4 className=' text-white'><span className='text-gradient'>1K supply </span> FOUNDERS <span className='d-none d-sm-inline-block'>NFT </span> PASS </h4> </div> */}
            </div>
            <div className='text-white text-sm-center pt-4'>
              <p>Welcome to Chain Warz, the ultimate decentralized, gamified sweepstakes lottery platform that will leave you breathless. Prepare to embark on a thrilling journey through a captivating dystopian world. </p>
              <p>At Chain Warz, we've assembled a diverse and seasoned team of nine experts (including AI as an advisor), each bringing their unique expertise to the table. With backgrounds in web3, web2, cybersecurity, Discord moderation, Twitter advertising, NFTs, and DeFi, we're a force to be reckoned with. Together, we've harnessed our exceptional skills to create the next groundbreaking phenomenon: DeLo, the decentralized Lottery. And we did it all during the harshest bear market, proving that we're fearless and unstoppable.</p>
              <p>Proudly built on the Ethereum blockchain, Chain Warz employs cutting-edge technology to ensure fairness and transparency in every game. By utilizing the revolutionary provably fair RNG technology, supported by Chainlink VRF on the blockchain, we guarantee a secure and trustworthy gaming experience. Rest assured, our smart contracts have been audited by the esteemed company Certik, giving you peace of mind.</p>
              <p>Witness relentless robot fighters facing off against unpredictable challenges, putting your luck to the test. But that's just the beginning. Our vision includes introducing a skill-based element to these battles, taking the excitement to a whole new level.
              </p>
              <p>We're not just building a platform; we're fostering a dynamic and engaged community. Join us on this thrilling journey. Become part of our vibrant community and cheer on your favorite robot fighters as they strive for victory.</p>
              <p>The launch of Chain Warz is right around the corner, and we couldn't be more thrilled to have you on board. Let's embark on this extraordinary adventure together. Engage, play, and let's create something truly remarkable! Don't miss out on the opportunity to be a part of history in the making.</p>
              {/* <button className='btn btn-default my-4'><span className='btn-default-text '>OPENSEA</span></button> */}
            </div>

          </div>
        </div>
      </section>
      {/* <section className='py-equal bg-dark-1 team'>
                <div className="container">
                    <div className="heading-element text-center pb-5 mb-3">
                        <h2 className='text-white text-shadow text-stroke '>The Team</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team1} alt="" /></figure>
                                <h3 className='text-white text-shadow'>Dreamer</h3>
                                <p className='text-white'>co-founder & <br />ceo</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team3} alt="" /></figure>
                                <h3 className='text-white text-shadow'>RoRo</h3>
                                <p className='text-white'>co-founder & <br />ceo</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team2} alt="" /></figure>
                                <h3 className='text-white text-shadow'>cookie monster</h3>
                                <p className='text-white'>Co-founder & Project Advisor</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>

                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team4} alt="" /></figure>
                                <h3 className='text-white text-shadow'>Chewbacca</h3>
                                <p className='text-white'>co-founder & <br />ceo</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team5} alt="" /></figure>
                                <h3 className='text-white text-shadow'>Khalnayak</h3>
                                <p className='text-white'>co-founder & <br />ceo</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team6} alt="" /></figure>
                                <h3 className='text-white text-shadow'>samuel</h3>
                                <p className='text-white'>community manager</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team7} alt="" /></figure>
                                <h3 className='text-white text-shadow'>jackson</h3>
                                <p className='text-white'>Collab Manager </p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 mb-3">
                            <div className='team-area rounded-4 text-center py-5 '>
                                <figure><img src={team8} alt="" /></figure>
                                <h3 className='text-white text-shadow'>beegoe</h3>
                                <p className='text-white'>ceo</p>
                                <button className='btn bg-white rounded-3 p-3'> <img src={twitter} /> </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      <section className='py-equal bg-dark-1' id='faq'>
        <div className="container">
          <div className="heading-element text-center">
            <h2 className='text-white text-shadow text-stroke pb-5 mb-3' >FAQ</h2>
          </div>
          <div>
            <Accordion defaultActiveKey="0" className='accordion-box' >
              <Accordion.Item eventKey="0" >
                <Accordion.Header className='text' >What is Chain Warz?</Accordion.Header>
                <Accordion.Body>
                  Chain Warz is an innovative blockchain-based lottery where each lottery ticket transforms into a fierce fighting bot, primed for combat.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How does Chain Warz work?</Accordion.Header>
                <Accordion.Body>
                  Participants can acquire fighting bots and engage in thrilling battles. When the dust settles, our smart contract selects a champion, rewarding them with ETH.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header >How frequently are battles organized?</Accordion.Header>
                <Accordion.Body>
                  Battles are held periodically to maintain the excitement. To stay updated, subscribe to our newsletter with your email on our website.
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="3">
                <Accordion.Header >How can I participate in a Chain Warz battle?</Accordion.Header>
                <Accordion.Body>
                  Engaging in a Chain Warz battle is straightforward. Purchase a bot for the battle of your choice. The more bots you possess, the better your chances of victory.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header >How is the champion of each battle chosen?</Accordion.Header>
                <Accordion.Body>
                  The victor of each battle is determined at random by our smart contract, with assistance from Chainlink VRF. This ensures a fair and transparent selection, with the entire process recorded on-chain for provable fairness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header >What are the implications of the battle leaderboards?</Accordion.Header>
                <Accordion.Body>
                  Battle leaderboards celebrate our proactive and enthusiastic community members. They epitomize our quest to make the platform enthralling. Stay active in battles and await potential rewards. Hint: Airdrops might be in the offing!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header >What does the allow list represent?</Accordion.Header>
                <Accordion.Body>
                  The allow list lets us intimately engage with our community and reward their dedication. This could provide them access to our private token sale, should we have one in the future.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header >Is my investment in Chain Warz secure?</Accordion.Header>
                <Accordion.Body>
                  Security is paramount at Chain Warz. With smart contracts vetted by companies like CertiK, we assure a trustworthy experience. Always access our official site to avoid phishing attempts.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header >What if I lose my wallet access?</Accordion.Header>
                <Accordion.Body>
                  Safeguard your wallet credentials, as Chain Warz cannot access individual wallets. In case of access loss, reach out to your wallet provider.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header >Are there country restrictions for Chain Warz participation?</Accordion.Header>
                <Accordion.Body>
                  Due to regulatory constraints, residents of Afghanistan, Aruba, Austria, Australia, Curaçao, France, Iraq, Iran, Netherlands, North Korea, Sint Maarten, the UK, and the USA cannot partake. This adherence ensures compliance with our Curacao master license.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header >What fees come with Chain Warz?</Accordion.Header>
                <Accordion.Body>
                  A gas fee applies for ETH deposits or withdrawals. Once funded, every bot acquisition gets noted on a decentralized relayer, making each battle entry free of gas.
                </Accordion.Body>  
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      <section className='py-equal bg-dark-1'>
        <div className="container our_journey wrap_our ">
          <div className="row ">
            <h4 className=" this text-center" id="our_journey">PROJECT ROADMAP</h4>
            <div className="heading-element mt-2">
              <h2 className='text-white text-shadow text-center text-stroke'>our journey</h2>
            </div>
            <div className="   col-lg-3 col-12 mt-2 ">
              <div
                className=" text-white btn-primary btn-header launch text-center btn-gadient" >
                1.LAUNCH
              </div>

              <div className="form-check form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  value=""
                  checked={false}
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck1">
                  POM BLOCKCHAIN LAUNCH
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space "
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check2"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check2">
                  MEME CONNECT
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check10"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check10">
                  POM CLAIM DASHBOARD
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="defaultCheck4"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck4">
                  10,000 TELEGRAM MEMBERS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="defaultCheck5"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck5">
                  COINGECKO / CMC LISTINGS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="defaultCheck6"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck6">
                  EXCHANGE LISTINGS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="defaultCheck7"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck7">
                  MEMEX SWAP
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="defaultCheck8"
                ></input>
                <label className="form-check-label pt-1" htmlFor="defaultCheck8">
                  PONG GOVERNANCE
                </label>
              </div>
            </div>

            <div className=" col-lg-3 col-12">
              <div

                className="    text-light btn-header growth text-center  mt-2">
                2.GROWTH
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check11"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check11">
                  EXPAND POM TEAM
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  value=""
                  checked={false}
                  id="check12"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check12">
                  GLOBAL PARTNERSHIPS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check13"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check13">
                  BRIDGEKEEPER STAKING
                </label>
              </div>

              <div className="form-check   form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check14"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check14">
                  BLOCKCHAIN SECURITY AUDITS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check15"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check15">
                  POM GITHUB
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space "
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check16"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check16">
                  PROJECT ONBOARDING
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  value=""
                  checked={false}
                  id="check17"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check17">
                  V2 WEBSITE
                </label>
              </div>
            </div>

            <div className=" col-lg-3 col-12 ">
              <div

                className="   stable text-white btn-header text-center  growth mt-2"
              >
                3.STABLE
              </div>
              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check18"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check18">
                  25,000 HOLDERS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check19"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check19">
                  ADDTIONAL EXCHANGE LISTINGS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check20"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check20">
                  POM PRESS RELEASES
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check21"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check21">
                  50,000 TELEGRAM MEMBERS
                </label>
              </div>
            </div>

            <div className=" col-lg-3 col-12">
              <div
                className="   expansion text-white text-center btn-header expansion mt-2"
              >
                4.EXPANSION
              </div>
              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check22"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check22">
                  POM UTILITY ANNOUNCEMENTS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check23"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check23">
                  POM GREEN MINING PROGRAM
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check24"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check24">
                  POM CRYPTO TELEGRAM FORK RELEASE
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check25"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check25">
                  ADDITIONAL PARTNERSHIPS AND SPONSORSHIPS
                </label>
              </div>

              <div className="form-check  form_check  py-3">
                <input
                  className="form-check-input defaultCheck_space"
                  type="checkbox"
                  checked={false}
                  value=""
                  id="check26"
                ></input>
                <label className="form-check-label pt-1" htmlFor="check26">
                  EXPANDS SOCIAL MEDIA PRESENCE AND MARKETING
                </label>
              </div>
            </div>



          </div>
        </div>
      </section>








      <section className='about bg-dark-1'>
        <div className="container ">
          <div className='about-element py-5 '>
            <div className="heading-element text-center pb-5 mb-3">
              <h2 className='text-white text-shadow text-stroke'>Proudly Audited BY</h2>
            </div>
            <div className="row audited">
              {/* <div className="col-md-4 mb-3">
                                <div className='rounded-3 audited-area py-5 text-center'>
                                    <img src={client1} alt="" />
                                </div>
                            </div> */}
              <div className="col-md-4 mb-3 mx-auto">
                <div className='rounded-3 audited-area text-center px-3'>
                  <img src={client2} alt="" />
                </div>
              </div>
              {/* <div className="col-md-4 mb-3">
                            <div className='rounded-3 audited-area py-5 text-center'>
                                    <img src={client3} alt="" />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className='about bg-dark-1 pt-5'>
        <div className="container">
          <div className='about-element py-equal '>
            <div className="row align-items-center mx-0 ">
              <div className="col-md-6 ps-md-5">
                <div className="heading-element">
                  <h2 className='text-white text-shadow  text-stroke subs '>Subscribe</h2>
                  <p className='text-uppercase text-white'>to be automatically notified  of upcoming battles</p>
                </div>
                <div>
                  <form onSubmit={formik.handleSubmit} >
                    <div className='form-group'>
                      <label className='text-white'>E-mail</label>
                      <input name='email' type="email" onChange={formik.handleChange} value={formik.values.email} placeholder='Your e-mail' className='form-control subscribe mb-3' />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-danger">
                          {formik.errors.email}
                        </p>
                      ) : null}
                      <button type='submit' className='btn btn-default'><span className='btn-default-text'>subscribe</span></button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <figure>
                  <img src={robot} alt="" className='w-50' />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>









    </>
  )
}

export default Home;