import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import "./leaderboard.scss";
import { get } from '../../utils/api/apiHelper';
import PaginationComponent from '../Pagination';
import { useSelector } from 'react-redux';

// import sort from "../../assets/images/sortbtn.svg";

function Leaderboardcomponent() {
  const { userId } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isFetching, setFetching] = useState(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);


  const getColorClass = (multiplier) => {
    if (multiplier === 2) {
      return 'color-2x'; // Use your preferred class name for 2x multiplier
    } else if (multiplier > 2) {
      return 'color-greater'; // Use your preferred class name for greater than 2x multiplier
    } else {
      return 'color-less'; // Use your preferred class name for less than 2x multiplier
    }
  };


  const getAllLeaderboard = useCallback(async () => {
    try {
      setFetching(true)
      let response = await get(`/client/get_leaderboard?pageNumber=${currentPage}&pageSize=${perPage}`);
      if (response.status === 200) {
        console.log(response.data.items, 'response.data.items');
        setItems(response.data.items);
        setTotal(response.data?.totalItems ?? 0);
        setPerPage(response.data.pageSize);
        // } else {
        //     toast.error('Failed to fetch data!');
      }
      setFetching(false)
    } catch (error) {
      toast.error('Something went wrong!',{
        toastId:"error"
      });
      setFetching(false)
    }
  }, [currentPage, perPage]);
  useEffect(() => {
    getAllLeaderboard();
  }, [getAllLeaderboard])

  return (
    <div className="wrap pt-head">
      <div className="container">
        <div className="row">
          <h1 className="text-white text-shadow text-center text-stroke  head-ref ">
            referral 24 H Leaderboard{" "}
          </h1>
          <p className="share text-white">
            Share your referral link to get more rewards! Move up in the table
            and get on the top step for the highest reward
          </p>
        </div>

        <div className="bg-dark-1 leaderboader-element">
          <div className="container ">
            <div className="">
              <div className="row mx-0 py-4">
                <div className=" col-md-1 col-6 ">
                  <p className="text-gray mb-0">Position</p>
                </div>
                <div className=" col-6 col-md-3 text-md-center text-end">
                  <p className="text-gray mb-0">name</p>
                </div>
                <div className=" col-md-2    text-center d-none d-md-block">
                  <p className="text-gray mb-0">multiplier</p>
                </div>
                {/* <div className=" col-md-2 text-center d-none d-md-block">
                  <p className="text-gray mb-0">streak</p>
                </div> */}
                <div className=" col-md-2 text-center d-none d-md-block">
                  <p className="text-gray mb-0">referral code</p>
                </div>
                <div className=" col-md-2  text-center d-none d-md-block">
                  <p className="text-gray mb-0">24H points</p>
                </div>
                <div className="  col-md-2  text-end d-none d-md-block">
                  <p className="text-gray mb-0">total points</p>
                </div>
              </div>
              <div>
                {

                  total <= 0 && !isFetching &&
                  <p className="text-warning text-center fs-5">
                    No Data Found !
                  </p>
                }
                {
                  isFetching &&
                  <p className="text-warning text-center fs-5">
                    Loading ...
                  </p>
                }
                {
                  !isFetching && total > 0 && items.map((item, i) => {
                    return (
                      <div className={`${userId === item?._id && 'bg-gadient bg-animation'} battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix`} key={i}>
                        <div className={`${userId === item?._id ? "bg-blue" : "border"} rounded-4 overflow-hidden`}>
                          <div className="row mx-0 align-items-center py-3 ">
                            <div className=" col-md-1 mb-3 mb-md-0 col-6 ">
                              <p className="text-gray text-center">{((currentPage - 1) * perPage) + (i + 1)}</p>
                            </div>
                            <div className="col-md-3 col-6 mb-3 mb-md-0 text-end text-md-center">
                              <p className="text-white">
                                {item?.userName}
                              </p>
                            </div>
                            <div className=" col-6   text-start  mb-2 mb-md-0 d-md-none">
                              <p className="text-gray mb-0">multiplier</p>
                            </div>
                            {/* <div className="col-md-2 col-6 mb-3 mb-md-0 text-end text-md-center">
                              <p className="text-cyan d-inline-block text-truncate text-elips">
                                <span className="one">{item?.tier?.multiplier} x</span>
                              </p>
                            </div> */}

                            <div className="col-md-2 col-6 mb-3 mb-md-0 text-end text-md-center">
                              <p className={`d-inline-block text-truncate ${getColorClass(item?.tier?.multiplier)}`}>
                                <span className="">{item?.tier?.multiplier} x</span>
                              </p>
                            </div>

                            {/* <div className=" col-6   text-start  mb-3 mb-md-0 d-md-none">
                                <p className="text-gray mb-0">streak</p>
                              </div>
                              <div className="col-md-2  col-6 mb-3 mb-md-0 text-end text-md-center">
                                <p className="text-gray "><span className="one1">100%</span></p>
                              </div> */}
                            <div className=" col-6   text-start  mb-3 mb-md-0 d-md-none">
                              <p className="text-gray mb-0">Refrral Code</p>
                            </div>
                            {/* col-md-3 col-6 mb-3 mb-md-0 text-end text-md-center */}
                            <div className="col-md-3 col-6 mb-3 mb-md-0 text-end text-md-center ">
                              <p className=" mb-0  text-cyan d-inline-block text-break">{item?.referral_code}</p>
                            </div>
                            <div className=" col-6   text-start  mb-3 mb-md-0 d-md-none">
                              <p className="text-gray mb-0">24 H points</p>
                            </div>
                            <div className=" col-md-1 col-6 mb-3 mb-md-0 text-end text-md-center">
                              <p className="text-white">{item?.points_24hrs}</p>
                            </div>
                            <div className=" col-6   text-start  mb-3 mb-md-0 d-md-none">
                              <p className="text-gray mb-0">Total Points</p>
                            </div>
                            <div className="col-md-2 col-6  mb-3 mb-md-0 text-end text-md-center ">
                              <p className="text-white text-end mb-0">{item?.points}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className='text-center mt-5'>
          <div className="pb-4 mt-4 ">
            {total > 0 && (
              <PaginationComponent
                className="mx-auto"
                itemsCount={total}
                itemsPerPage={perPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaderboardcomponent;
