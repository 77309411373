import { embeddedWallet, smartWallet, useAddress } from "@thirdweb-dev/react";
import { EmbeddedWallet } from "@thirdweb-dev/wallets";
import { Sepolia } from "@thirdweb-dev/chains";
import config from "../config.json";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setLoading, showConnectModal } from "../redux/slices/commonSlice";
import { useNavigate } from "react-router-dom";
import { get } from "./api/apiHelper";
import { login } from "../redux/slices/userSlice";
const wallet = new EmbeddedWallet({
    clientId: `${config.REACT_PUBLIC_TEMPLATE_CLIENT_ID}`,
    chain: Sepolia,
});

const embeddedWalletConfig = embeddedWallet({
    auth: {
        options: ["email"],
    },
});
const smartWalletConfig = smartWallet(embeddedWalletConfig, {
    factoryAddress: config.ACCOUNT_FACTORY_ADDRESS,
    gasless: true,
})

const privateKeyLink = () => {
    return <a target="_blank" rel="noreferrer" style={{ fontSize: "smaller" }} href={`https://embedded-wallet.thirdweb.com/sdk/2022-08-12/embedded-wallet/export?clientId=${config.REACT_PUBLIC_TEMPLATE_CLIENT_ID}`}>Export your wallet</a>
}

const useConnection = () => {
    const isConnected = useAddress();
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const checkConnection = useCallback((force = true) => {
        dispatch(setLoading(true));
        if (isConnected) {
            get('/client/profile').then(response => {
                if (response?.status === 200) {
                    let token = localStorage.getItem('accessToken');
                    dispatch(login({ ...response.data, token }));
                } else {
                    redirect('/battles')
                }
            }).finally(() => {
                dispatch(setLoading(false));
            });
        } else {
            dispatch(setLoading(false));
            if (force) {
                dispatch(showConnectModal(true))
                redirect('/battles')
            }
        }
    }, [dispatch, isConnected, redirect])

    return { checkConnection }
}
export { wallet, smartWalletConfig, privateKeyLink, useConnection }