export const MODE = 'development';
export const STORAGE_KEYS = {
    twitter: "user_twitter_creds",
    discord: "user_discord_creds"
}
export const discord_url = 'https://discord.com/api/oauth2/authorize?client_id=1199281069548118056&response_type=code&redirect_uri=https%3A%2F%2Fchainwarz.com%2Fallowlist&scope=identify+guilds+gdm.join+email+guilds.join';
export const discord_local_url = 'https://discord.com/api/oauth2/authorize?client_id=1199281069548118056&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fallowlist&scope=identify+guilds+gdm.join+guilds.join+email';
export const walletConnectProjectId = 'c32c430eb959e2b14e2365cc8e543a5c';
export const firebaseConfig = {
    apiKey: "AIzaSyDJnghNRW2JfBP1RSTNtYiwze9ppvvgI9E",
    authDomain: "chainwarz-btb.firebaseapp.com",
    projectId: "chainwarz-btb",
    storageBucket: "chainwarz-btb.appspot.com",
    messagingSenderId: "1098062710640",
    appId: "1:1098062710640:web:442b37b924f04be035f1b5",
    measurementId: "G-ZS4K93MG21"
};