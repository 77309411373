import "./modal.scss";
import { React } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { get, put } from "../../utils/api/apiHelper";
import { toast } from "react-toastify";
import axios from "axios";

const Otpverify = (props) => {
  const [otp, setOtp] = useState("");
  // const [isVerifying, setIsVerifying] = useState(false)
  // const verifyOtp = async () => {
  //   try {
  //     // setIsVerifying(true)
  //     // const response = await get(`/client/verifyOtp?email=${props?.formData?.email}&otp=${otp}`);
  //     const response = await get(`/client/verifyOtp?email=${props?.formData?.email}&otp=${otp}`);
  //     if (response.status === 200) {
  //       toast.success(response?.message);
  //       props?.onHide()
  //       await props?.submitForm(props?.formData)
  //     } else {
  //       toast.error(response?.error ?? "Something went wrong.",{
  //         toastId:"error"
  //       });
  //     }
  //     // setIsVerifying(false)
  //   } catch (error) {
  //     console.log(error, 'verify otp error');
  //     // setIsVerifying(false)
  //   }
  // }

  const verifyOtp = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("user-info")).token;
      const response = await axios.get(
        `https://api.chainwarz.com/api/client/verify_otp?email=${props?.formData?.email}&otp=${otp}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(response?.data?.message);
      props?.onHide();
      await props?.submitForm(props?.formData);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error?.response?.data?.error ?? "Something went wrong.", {
        toastId: "error",
      });
      return null;
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="bg-blue popup-element rounded-5 connect-element  p-0 pb-4 text-center pt-2 pb-5">
        <div className="text-end mb-2 px-4">
          <Button
            variant="link"
            onClick={props.onHide}
            className="text-decoration-none light-text"
          >
            X
          </Button>
        </div>
        <div className="">
          <div className="py-5  px-4">
            <h2 className="text-white">verify Your Email address</h2>
            <h6 className="text-white mt-4">
              CHECK YOUR EMAIL, WE HAVE SENT A VERIFICATION CODE
            </h6>
            <div className="col-md-10 mx-auto mt-5 ">
              <h6 className="text-white">ENTER YOUR VERIFICATION CODE HERE</h6>
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputStyle="otp-field my-4"
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              <button
                className="btn btn-default btn-next mt-4"
                // disabled={isVerifying}
                onClick={verifyOtp}
              >
                <span className="btn-default-text">verify</span>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Otpverify;
