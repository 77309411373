export default function Stepper({ current, total }) {
    return (
        <ul className="list-unstyled d-flex justify-content-center mx-auto text-white hei-widt">
            {
                Array(total).fill(null).map((_, i) => {
                    return (
                        <li key={i} className={`border-rounded page-item rounded-3 border border-1 ${current === i + 1 ? "bg-gadient" : ""}`}>
                            <button
                                className={`btn border-0 ${current === i + 1 ? "text-white" : "text-gradient"}`} key={i}
                            >
                                {i + 1}
                            </button>
                        </li>
                    )
                })
            }

        </ul>
    )
}

