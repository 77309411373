import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import PubNubChat from '../../utils/pubnub-chat';
import './live-chat.component.scss';
import MessageContent from './message-content/message-content';
import { toast } from 'react-toastify';

export default function LiveChatComponent({
    canSend
}) {
    const channelId = "chainwarz_test";
    const user = useSelector((state) => state.user);
    const [pubNubChat, setPubNubChat] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [messageInput, setMessageInput] = useState('');

    const sendMessage = async () => {
        if (messageInput) {
            await pubNubChat.sendMessage(messageInput, user);
            setMessageInput('');
        } else {
            toast.info('Please type something to send !'.toUpperCase(), {
                toastId: "msg-info"
            })
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the default behavior (e.g., newline in the input field)
            sendMessage();
        }
    };

    useEffect(() => {
        const pnClient = new PubNubChat();
        setPubNubChat(() => pnClient);
        pnClient.init(user.userId, channelId).then(async () => {
            const messages = await pnClient.getMessageHistory([user]);
            setMessageList(() => [...messages]);
        }).then(() => {
            console.log('in then <--');
            if (pnClient.channel) {
                // pnClient.channel.connect((message) => {
                //     console.log(message, 'new message');
                //     pnClient.getMessageHistory([user]).then((messages) => setMessageList(() => [...messages])).catch((error) => console.log(error))
                // })
                pnClient.channel.connect((message) => {
                    console.log(message, 'new message');
                    pnClient.getMessageHistory([user])
                        .then((newMessages) => {
                            console.log('Received new messages:', newMessages);
                            setMessageList((prevMessages) => [...prevMessages, ...newMessages]);
                        })
                        .catch((error) => console.log(error));
                });
            }
            console.log('--> out of then');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function scroolTop() {
        const element = document.getElementById('message-list');
        element.scrollTop = element.scrollHeight;
    }
    useEffect(() => {
        scroolTop();
    }, [messageList])
    return (
        <Accordion defaultActiveKey="0" flush className="">
            <Accordion.Item eventKey="0">
                <Accordion.Header className='bordertop'>
                    <h3>Live Chat</h3>
                </Accordion.Header>
                <Accordion.Body className='p-0'>
                    <div className="message-list overflow-auto scroll-white" id='message-list'>
                        {
                            Boolean(messageList?.length)
                                ?
                                messageList?.map((message, i) => {
                                    return (<MessageContent key={i} message={message} user={user} />)
                                })
                                :
                                <p className='h-100 text-cyan text-center'>
                                    No Message Found !
                                </p>
                        }
                    </div>
                    <div className="position-relative mt-2">
                        <input placeholder="Type a message" type={'text'} name='message' value={messageInput} onChange={(event) => setMessageInput(event.target.value)} 
                        onKeyDown={handleKeyDown}
                         className="bg-blue  message-input text-white form-control" />
                        <button
                            className={`passeye rounded p-3 position-absolute btn btn-primary`}
                            onClick={sendMessage}
                            disabled={!Boolean(canSend)}
                        >
                            Send
                        </button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>);
}