import './modal.scss';
import { React } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { showRefferModal } from '../../redux/slices/commonSlice';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { setLoading } from "../../redux/slices/commonSlice";
import { postAsForm } from "../../utils/api/apiHelper";
import { login } from "../../redux/slices/userSlice";

const RefferalPopup = () => {
    const dispatch = useDispatch();
    const { showRefferModal: show } = useSelector((state) => state.common);
    const handleHide = () => {
        dispatch(showRefferModal(false))
    }

    const formik = useFormik({
        initialValues: {
            referredByCode: "",
        },
        onSubmit: async (values) => {
            try {
                console.log(values, 'values');
                dispatch(setLoading(true));
                const formData = new FormData();
                formData.append("referredByCode", values?.referredByCode);
                const response = await postAsForm('/client/updateProfile', formData);
                dispatch(setLoading(false));
                if (response.status === 200) {
                    dispatch(login({ ...response.data }));
                    toast.success(response?.message);
                    dispatch(showRefferModal(false))
                } else {
                    toast.error(response?.error ?? "Something went wrong.", {
                        toastId: "error"
                    });
                }

            } catch (error) {
                dispatch(setLoading(false));
                console.log(error);
                toast.error('Failed To Save !', {
                    toastId: "error"
                });
            }
        }
    });

    return (
        <Modal
            show={show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleHide}
        >
            <Modal.Body className='bg-blue popup-element rounded-5 connect-element  p-0 pb-4 text-center pt-2 pb-5'>
                <div className='text-end mb-2 px-4'><Button variant="link" onClick={handleHide} className='text-decoration-none light-text'>X</Button></div>
                <h3 className='text-white'>Reffer By Someone</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group w-75 mx-auto py-4">
                        <input placeholder="Enter referral code"
                            type="text"
                            className="form-control p-2 bg-transparent text-white"
                            name='referredByCode'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.referredByCode}
                        />
                    </div>
                    <button className="cursor-pointer btn btn-default py-2 w-50"><span className="text-gradient">Save</span> </button>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
      </Modal.Footer> */}
        </Modal>
    )
}

export default RefferalPopup;