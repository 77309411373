import React from 'react'
// import Dropdown from 'react-bootstrap/Dropdown';
// import sort from "../../../assets/images/sortbtn.svg"
// import eth from "../../../assets/images/ethlogo.svg"
// import share from "../../../assets/images/share.svg"
import eth from '../../../assets/images/ethereum-svg.svg'
// import usdt from "../../../assets/images/usdt.svg"
// import bitcoin from "../../../assets/images/Bitcoin.svg"
// import dogecoin from "../../../assets/images/Dogecoin.svg"
// import ethereum from "../../../assets/images/ethereum-eth-logo.svg"
// import { BsPlus } from 'react-icons/bs';
import './wallethistory.scss'
import { fullDateFormat, showSlicedText } from '../../../utils/common';
import { formatEther } from 'ethers/lib/utils';
import { useAddress } from '@thirdweb-dev/react';
import PaginationComponent from '../../Pagination'

function Wallethistorycomponent({
    items,
    total,
    perPage,
    currentPage,
    setCurrentPage,
}) {
    const address = useAddress();
    return (
        <div>
            <div className="bg-dark-1 mt-3 ">
                <div className="container ">
                    <div className="battle-enter" >
                        {

                            total > 0 &&
                            <div className="row mx-0 py-4">
                                <div className=" col-6 col-md-2">
                                    <p className="text-gray mb-0">Action</p>
                                </div>
                                <div className=" col-6 col-md-2  text-md-start text-end">
                                    <p className="text-gray mb-0">Status</p>
                                </div>
                                <div className=" col-6 col-md-3  text-md-start text-end">
                                    <p className="text-gray mb-0">Amount</p>
                                </div>
                                <div className="col-md-3  d-none d-md-block">
                                    <p className="text-gray mb-0">Hash</p>
                                </div>
                                <div className="col-md-2 text-end d-none d-md-block">
                                    <p className="text-gray mb-0">Time</p>
                                </div>
                            </div>
                        }
                        <div>
                            {
                                total <= 0 &&
                                <p className="text-warning text-center fs-5" key={Math.random()}>
                                    Sorry you have not done any transaction !
                                </p>
                            }
                            {
                                total > 0 && items?.map((data) => {
                                    return (
                                        <div className="bg-gadient battle-enter-area d-inline-block  w-100 rounded-4 mb-3 clearfix" key={data?.hash}>
                                            <div className="bg-dark-1 rounded-4">

                                                <div className="row mx-0 pt-4  py-1">
                                                    <div className="col-6 col-md-2  text-start  mb-md-0">
                                                        <p className="text-gray one1 ">{data?.from === address ? "Send" : "Receive"}</p>
                                                    </div>
                                                    <div className="col-6 col-md-2  text-start  mb-md-0">
                                                        <p className="text-gray one1 ">{data?.txreceipt_status === "1" ? "Success" : "Fail"}</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 text-md-start text-end mb-2 mb-md-0">
                                                        <p className="text-cyan mb-0"><img src={eth} width={20} className="me-2 " alt="" />{formatEther(data?.value)} eth </p>
                                                    </div>
                                                    <div className=" col-6 col-md-3  d-block d-md-none mb-2 mb-md-0">
                                                        <p className="text-gray mb-0">hash</p>
                                                    </div>
                                                    <div className="col-6 col-md-3 text-end text-md-start mb-2 mb-md-0" onClick={() => window.open(`https://sepolia.etherscan.io/address/${address}`)} >
                                                        <p className="text-gray d-inline-block text-truncate cursor-pointer text-elips text-cyan">{showSlicedText(data?.hash ?? '')}</p>
                                                    </div>
                                                    <div className="col-md-2 col-12 text-center text-md-end pt-3 pt-md-0">
                                                        <p className="text-gray  "> {fullDateFormat(+data?.timeStamp * 1000)}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            {/* <div className="pb-4 mt-4 ">
                                {total > 0 && (
                                    <div className='btn-load bg-gadient mx-auto rounded-4'>
                                        <div className=" bg-dark-1 btn-load-inner position-relative">
                                            <button onClick={() => window.open(`https://goerli.etherscan.io/address/${address}`)} className="btn btn-default shadow-0 bg-dark-1 mb-0 w-100">
                                                <span className="btn-default-text">View More</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                            <div className="pb-4 mt-4 ">
                                {total > 0 && (
                                    <PaginationComponent
                                        className="mx-auto"
                                        itemsCount={total}
                                        itemsPerPage={perPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wallethistorycomponent